import React, { useCallback } from "react";
import { Trans } from "@lingui/macro";
import { ExternalLink } from "@trustfractal/seabed";

import Button from "components/common/Button";
import Notice from "components/common/Notice";

import { generalTcForDataProtection, termsAndConditions, subprocessorsList } from "lib/nautilusLinks";
import { useAppDispatch } from "lib/hooks";
import { acceptPolicy } from "actions/currentUser";

export function PolicyAcceptance() {
  const dispatch = useAppDispatch();

  const onAccept = useCallback(() => {
    dispatch(acceptPolicy());
  }, [dispatch]);

  return (
    <Notice icon="warning">
      <h6>
        <Trans id="policy_acceptance.title">Welcome to Fractal ID Developer Dashboard</Trans>
      </h6>

      <p>
        <Trans id="policy_acceptance.message.heading">By clicking the button you agree to the Fractal ID</Trans>{" "}
        <ExternalLink href={termsAndConditions}>
          <Trans id="policy_acceptance.policy_link">Terms of Service</Trans>
        </ExternalLink>{" "}
        <Trans id="policy_acceptance.message.agree">and confirm you read the</Trans>{" "}
        <ExternalLink href={generalTcForDataProtection}>
          <Trans id="policy_acceptance.gdpr_link">General Terms and Conditions for Data Protection</Trans>
        </ExternalLink>
        {"."}
        <br />
        <Trans id="policy_acceptance.message.list_of">Our list of</Trans>{" "}
        <ExternalLink href={subprocessorsList}>
          <Trans id="policy_acceptance.subprocessors_link">sub-processors is available here</Trans>
        </ExternalLink>
        .
        <br />
      </p>

      <Button className="primary fullwidth" onClick={onAccept}>
        <Trans id="policy_acceptance.submit">I agree!</Trans>
      </Button>
    </Notice>
  );
}

export default PolicyAcceptance;
