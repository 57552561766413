import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Trans } from "@lingui/macro";
import { useNavigate } from "react-router-dom";

import { goBackUrl } from "lib/browser";

import { createApplication } from "actions/applications";

import ApplicationForm, { ApplicationFormData } from "components/ApplicationForm";

const mapDispatchToProps = {
  createApplication,
};

const connector = connect(undefined, mapDispatchToProps);
type ApplicationNewPropsFromRedux = ConnectedProps<typeof connector>;

export function ApplicationNew({ createApplication }: ApplicationNewPropsFromRedux) {
  const navigate = useNavigate();

  const onSubmit = (values: ApplicationFormData) => createApplication(values).then((data) => navigate(`/apps/${data.uid}`));

  return (
    <div className="page">
      <div className="page__header">
        <h2>
          <Trans id="application.create">Create new integration</Trans>
        </h2>
      </div>
      <div className="page__content application">
        <div className="applications__form--close" onClick={() => navigate(goBackUrl())}>
          <Trans id="applications.go_back">Back</Trans>
        </div>

        <ApplicationForm onSubmit={onSubmit} />
      </div>
    </div>
  );
}

export default connector(ApplicationNew);
