import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import Notice from "components/common/Notice";

export interface NotFoundProps {
  uri: string;
}

export const NotFound = ({ uri }: NotFoundProps) => (
  <Notice icon="error">
    <p>
      <Trans id="not_found.description">
        We couldn&apos;t find <code>{uri}</code>.
      </Trans>
    </p>
    <Link className="btn primary fullwidth" to="/">
      <Trans id="not_found.cta">Go to homepage</Trans>
    </Link>
  </Notice>
);

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export default NotFound as any as () => React.ReactElement;
