import { getType } from "typesafe-actions";
import { ActionTypes, setLanguage } from "actions/language";
import { detectLanguage } from "lib/i18n";

type State = string;

const initialState = detectLanguage();

export default (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case getType(setLanguage):
      return action.payload;

    default:
      return state;
  }
};
