import { useState, useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "store";
import axios from "axios";

export const useMimeType = (url?: string, overrideMimeType?: string): [boolean, string | null] => {
  const [mimeType, setMimeType] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const source = axios.CancelToken.source();

    async function doWork() {
      if (!url) {
        // This is because of https://github.com/testing-library/react-hooks-testing-library/issues/445
        await new Promise((resolve) => resolve(true));
        setLoading(false);
        setMimeType(null);
      } else if (overrideMimeType) {
        // This is because of https://github.com/testing-library/react-hooks-testing-library/issues/445
        await new Promise((resolve) => resolve(true));
        setMimeType(overrideMimeType);
        setLoading(false);
      } else {
        try {
          const { headers } = await axios.get(url, {
            headers: {
              range: "bytes=0-1",
            },
            cancelToken: source.token,
          });
          /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
          setMimeType(headers["content-type"] || "application/octet-stream");
          setLoading(false);
        } catch (err) {
          if (!axios.isCancel(err)) {
            setMimeType(null);
            setLoading(false);
          }
        }
      }
    }

    void doWork();

    return (): void => {
      source.cancel();
      setLoading(false);
    };
  }, []);

  return [loading, mimeType];
};

export const useDebounce = (value: string, delay: number): string => {
  const [debounce, setDebounce] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounce(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debounce;
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Check https://github.com/reduxjs/react-redux/issues/1678 this is not deprecated
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
