import React from "react";
import { t } from "@lingui/macro";
import { ExternalLink } from "@trustfractal/seabed";

import { useMimeType } from "lib/hooks";
import LoadingSpinner from "components/common/LoadingSpinner";

export const Document = ({ file, label, overrideMimeType }: { file?: string; label?: string; overrideMimeType?: string }): JSX.Element => {
  const [loading, mimeType] = useMimeType(file, overrideMimeType);

  if (loading) return <LoadingSpinner />;
  if (!file || (!loading && !mimeType)) return <span>No file provided</span>;

  return (
    <div className="user-details__document">
      {mimeType?.includes("image/") ? (
        <img src={file} alt={label} />
      ) : (
        <ExternalLink href={file}>{label || t({ id: "verification_case.view_file", message: "View file" })}</ExternalLink>
      )}
      <span>{label}</span>
    </div>
  );
};

export default Document;
