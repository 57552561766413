import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import store from "store";
import router from "router";

import I18nLoader from "components/I18nLoader";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <I18nLoader>
      <RouterProvider router={router} />
    </I18nLoader>
  </Provider>,
);
