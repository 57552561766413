import React, { useEffect } from "react";
import { Trans } from "@lingui/macro";

import { fetchTotalVerifications, fetchCountryVerifications, TotalVerifications, CountryVerifications, Application } from "lib/api";
import { goBackUrl } from "lib/browser";
import LoadingSpinner from "components/common/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "lib/hooks";

const TotalVerificationsView = ({ data, application }: { data: TotalVerifications; application: Application }) => (
  <div className="stats-total">
    <div>
      <b>
        <Trans id="verification_status.approved">Approved</Trans>
      </b>
      <br />
      {data.approved}
    </div>
    <div>
      <b>
        <Trans id="verification_status.contacted">Contacted</Trans>
      </b>
      <br />
      {data.contacted}
    </div>
    <div>
      <b>
        <Trans id="verification_status.pending">Pending</Trans>
      </b>
      <br />
      {data.pending}
    </div>
    <div>
      <b>
        <Trans id="verification_status.rejected">Rejected</Trans>
      </b>
      <br />
      {data.rejected}
    </div>
    {application.expirations_enabled && (
      <div>
        <b>
          <Trans id="verification_status.expired">Expired</Trans>
        </b>
        <br />
        {data.expired}
      </div>
    )}
  </div>
);

const NoDataRow = () => (
  <tr>
    <td colSpan={5}>
      <Trans id="country_verifications.no_data">No data</Trans>
    </td>
  </tr>
);

const DataLoadError = () => <Trans id="country_verifications.data_load_error">Data load error</Trans>;

const CountryVerificationsView = ({ data, application }: { data: CountryVerifications; application: Application }) => (
  <div className="country-verifications">
    <table>
      <thead>
        <tr>
          <th>
            <Trans id="country_verifications.country">Country</Trans>
          </th>
          <th>
            <Trans id="verification_status.approved">Approved</Trans>
          </th>
          <th>
            <Trans id="verification_status.contacted">Contacted</Trans>
          </th>
          <th>
            <Trans id="verification_status.pending">Pending</Trans>
          </th>
          <th>
            <Trans id="verification_status.rejected">Rejected</Trans>
          </th>
          {application.expirations_enabled && (
            <th>
              <Trans id="verification_status.expired">Expired</Trans>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {Object.keys(data).length === 0 ? (
          <NoDataRow />
        ) : (
          Object.entries(data).map(([country, values]) => (
            <tr key={country}>
              <td>{country}</td>
              <td>{values.approved || 0}</td>
              <td>{values.contacted || 0}</td>
              <td>{values.pending || 0}</td>
              <td>{values.rejected || 0}</td>
              {application.expirations_enabled && <td>{values.expired || 0}</td>}
            </tr>
          ))
        )}
      </tbody>
    </table>
  </div>
);

function ApplicationStats() {
  const [totalVerifications, setTotalVerifications] = React.useState<null | "error" | TotalVerifications>(null);
  const [countryVerifications, setCountryVerifications] = React.useState<null | "error" | CountryVerifications>(null);
  const { appId } = useParams<{ appId: string }>();
  const navigate = useNavigate();

  const application = useAppSelector((state) => {
    if (state.applications) {
      return state.applications.find((a) => a.uid === appId);
    }
    return null;
  });

  useEffect(() => {
    if (!application) return;

    fetchTotalVerifications(application)
      .then((data) => setTotalVerifications(data.data))
      .catch(() => setTotalVerifications("error"));

    fetchCountryVerifications(application)
      .then((data) => setCountryVerifications(data.data))
      .catch(() => setCountryVerifications("error"));
  }, [application]);

  if (!application) return <LoadingSpinner />;

  return (
    <div className="page">
      <div className="page__header">
        <h2>
          <Trans id="application.stats">Application Stats</Trans>
        </h2>
      </div>

      <div className="page__content application">
        <div className="application-stats">
          <div className="applications__form--close" onClick={() => navigate(goBackUrl())}>
            <Trans id="applications.go_back">Back</Trans>
          </div>

          <h2>{application?.name}</h2>

          <div className="application__card">
            <div className="application__card--header">
              <Trans id="stats.total_verifications">Total Verifications</Trans>
            </div>

            <div className="application__card--body">
              {totalVerifications === null && <LoadingSpinner />}
              {totalVerifications === "error" && <DataLoadError />}
              {totalVerifications !== "error" && totalVerifications && (
                <TotalVerificationsView data={totalVerifications} application={application} />
              )}
            </div>
          </div>

          <div className="application__card">
            <div className="application__card--header">
              <Trans id="stats.country_verifications">Verifications by Countries</Trans>
            </div>

            <div className="application__card--body">
              {countryVerifications === null && <LoadingSpinner />}
              {countryVerifications === "error" && <DataLoadError />}
              {countryVerifications !== "error" && countryVerifications && (
                <CountryVerificationsView data={countryVerifications} application={application} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationStats;
