import { createAction } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";

import { RootState } from "store";
import { webhooks, Webhook } from "lib/api";

export const setWebhooks = createAction("SET_WEBHOOKS")<Webhook[]>();
export const replaceWebhook = createAction("REPLACE_WEBHOOK")<Webhook>();

export type ActionTypes = ReturnType<typeof setWebhooks> | ReturnType<typeof replaceWebhook>;

type PluralThunkAction = ThunkAction<Promise<Webhook[]>, RootState, null, ActionTypes>;
type SingularThunkAction = ThunkAction<Promise<Webhook>, RootState, null, ActionTypes>;

export const fetchWebhooks =
  (): PluralThunkAction =>
  async (dispatch): Promise<Webhook[]> => {
    const data = await webhooks.all();

    dispatch(setWebhooks(data));
    return data;
  };

export const saveWebhook =
  (webhook: Partial<Webhook>): SingularThunkAction =>
  async (dispatch): Promise<Webhook> => {
    const data = await webhooks.create(webhook);

    dispatch(replaceWebhook(data));
    return data;
  };
