import { setUser } from "@sentry/browser";
import { pageRefresh } from "lib/browser";

const SESSION_KEY = "access_token";

export const session = {
  getToken: (): string | null => localStorage.getItem(SESSION_KEY),
  setToken: (token: string): void => localStorage.setItem(SESSION_KEY, token),
  clearToken: (): void => localStorage.removeItem(SESSION_KEY),
};

export const logout = (): void => {
  session.clearToken();
  setUser(null);
  pageRefresh("/");
};

export default session;
