import React, { Component } from "react";
import { connect } from "react-redux";
import { Trans, Plural } from "@lingui/macro";
import { Link } from "react-router-dom";
import { ExternalLink } from "@trustfractal/seabed";

const ApplicationItem = ({ image_url, name, users_count, uid }) => (
  <li className="card card--collapse">
    <Link to={uid}>
      <div className="application-item">
        <div className="application-item__image">
          <img src={image_url} alt={name} />
        </div>

        <div className="application-item__details">
          <span className="application-item__name">{name}</span>
          <small>
            <Plural
              id="application.users_count"
              value={users_count}
              _0="No authorized users"
              one="# authorized user"
              other="# authorized users"
            />
          </small>
        </div>
      </div>
    </Link>
  </li>
);

export class Applications extends Component {
  render() {
    const { applications } = this.props;

    return (
      <div className="page applications">
        <div className="page__header">
          <h2>
            <Trans id="applications.title">Integrations</Trans>
          </h2>
          <Link className="btn primary" to="/apps/new">
            <Trans id="applications.create">Create integration</Trans>
          </Link>
        </div>
        <div className="page__content">
          {applications.length === 0 && (
            <div className="applications__notice">
              <h4>
                <Trans id="applications.notice.title">You don't have any integrations yet.</Trans>
              </h4>

              <p>
                <Trans id="applications.notice.description">
                  Start setting up an integration by clicking "Create Integration". We recomend reading the{" "}
                  <ExternalLink href="https://docs.developer.fractal.id/" className="inline-block">
                    API documentation
                  </ExternalLink>{" "}
                  first.
                </Trans>
              </p>
            </div>
          )}

          <ul className="applications__list">
            {applications.map((application) => (
              <ApplicationItem key={application.uid} {...application} />
            ))}
          </ul>

          <div className="applications__help">
            <Trans id="applications.help_contact">
              Need help? Contact us through <a href="mailto:engineering@fractal.id">engineering@fractal.id</a>.
            </Trans>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ applications }) => ({ applications }))(Applications);
