import { memoize, pickBy, isNil } from "lodash-es";
import axios, { AxiosInstance, Method } from "axios";
import { VerificationCase, MegalodonVerificationCase, OctopusVerificationCase } from "lib/verification_case";
import qs from "qs";

import config from "lib/config";
import { barracuda, catfish, megalodon, octopus, sprat, HTTPResponse } from "lib/http";
import { ApplicationFormData } from "components/ApplicationForm";

export const REDIRECT_URI = `${config.BASE_URL}/session`;

export const fetchToken = (code: string): HTTPResponse<{ access_token: string }> =>
  sprat.post("/oauth/token", {
    code,
    client_id: config.OAUTH_CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    grant_type: "authorization_code",
  });

const fetchApplicationToken = memoize(
  (application: Application, scopes: string) =>
    catfish.post("/oauth/token", {
      grant_type: "client_credentials",
      scope: scopes,
      client_id: application.uid,
      client_secret: application.secret,
    }),
  (application, scopes) => `${application.uid} ${scopes}`,
);

const fetchWithApplicationToken =
  (animal: AxiosInstance) =>
  async <T>(
    application: Application,
    scope: string,
    endpoint: string,
    method?: Method,
    data?: Record<string, unknown>,
  ): HTTPResponse<T> => {
    const doWork = (): HTTPResponse<T> =>
      fetchApplicationToken(application, scope).then(({ data: { access_token } }) =>
        animal.request({
          url: endpoint,
          method: method || "get",
          headers: {
            Authorization: `Bearer ${access_token as string}`,
          },
          data,
        }),
      );

    return doWork().catch((e) => {
      if (axios.isAxiosError(e) && e.response?.status !== 401) return Promise.reject(e);

      if (fetchApplicationToken.cache.clear) {
        fetchApplicationToken.cache.clear();
      }

      return doWork();
    });
  };

const fetchOctopusWithApplicationToken = fetchWithApplicationToken(octopus);
const fetchMegalodonWithApplicationToken = fetchWithApplicationToken(megalodon);

export interface TotalVerifications {
  approved: number;
  contacted: number;
  pending: number;
  rejected: number;
  expired: number;
}

export const fetchTotalVerifications = (application: Application): HTTPResponse<TotalVerifications> =>
  fetchMegalodonWithApplicationToken(application, "client.stats:read", "/v2/stats/total-verifications");

export interface CountryVerifications {
  [countryCode: string]: Partial<TotalVerifications>;
}

export const fetchCountryVerifications = (application: Application): HTTPResponse<CountryVerifications> =>
  fetchMegalodonWithApplicationToken(application, "client.stats:read", "/v2/stats/country-verifications");

export const fetchVerificationCases = async (
  application: Application,
  offset: number,
  filter?: string,
  query?: string,
): Promise<VerificationCasesResponse> => {
  const params = pickBy({ offset, filter, query }, (v) => !isNil(v));

  const { data } = await fetchMegalodonWithApplicationToken<VerificationCasesResponse>(
    application,
    "client.verification_case:list client.verification_case:read",
    `/verification-cases?${qs.stringify(params)}`,
  );

  return data;
};

export const fetchCredentials = async (
  application: Application,
  offset: number,
  filter?: string,
  query?: string,
): Promise<CredentialsResponse> => {
  const params = { offset, filter, query };

  const { data } = await fetchMegalodonWithApplicationToken<CredentialsResponse>(
    application,
    "client.credential:list",
    `/credentials/list?${qs.stringify(params, { skipNulls: true })}`,
  );

  return data;
};

export const fetchMegalodonCredential = async (application: Application, credentialId: string): Promise<Credential> => {
  const { data } = await fetchMegalodonWithApplicationToken<Credential>(
    application,
    "client.credential:read",
    `/credentials/${credentialId}`,
  );

  return data;
};

export const fetchMegalodonVerificationCase = async (
  application: Application,
  verificationCaseId: string,
): Promise<MegalodonVerificationCase> => {
  const { data } = await fetchMegalodonWithApplicationToken<MegalodonVerificationCase>(
    application,
    "client.verification_case:list client.verification_case:read",
    `/verification-cases/${verificationCaseId}`,
  );

  return data;
};

export interface Export {
  id: string;
  status: "pending" | "done" | "failed";
  format: string;
  notified: boolean;
  created_at: Date;
  finished_at: Date;
  updated_at: Date;
  file_url?: string;
}

export const exportCredentials = (application: Application, scopedUserId: string) =>
  fetchMegalodonWithApplicationToken<Export>(application, "client.credential:list client.credential:read", "/credentials/exports", "post", {
    scoped_user_id: scopedUserId,
  });

export const exportVerificationCases = (application: Application, scopedUserId: string) =>
  fetchMegalodonWithApplicationToken<Export>(
    application,
    "client.verification_case:list client.verification_case:read",
    "/verification-cases/exports",
    "post",
    { scoped_user_id: scopedUserId },
  );

export const fetchCredentialsExportRequest = (application: Application) =>
  fetchMegalodonWithApplicationToken<Export[]>(application, "client.credential:list client.credential:read", "/credentials/exports");

export const fetchVerificationCasesExportRequest = (application: Application) =>
  fetchMegalodonWithApplicationToken<Export[]>(
    application,
    "client.verification_case:list client.verification_case:read",
    "/verification-cases/exports",
  );

export const fetchOctopusVerificationCase = async (
  application: Application,
  scopedUserId: string,
  level: string,
): Promise<OctopusVerificationCase> => {
  const { data } = await fetchOctopusWithApplicationToken<OctopusVerificationCase>(
    application,
    "client.verification_case:read",
    `/api/verification-cases/${scopedUserId}/${level}`,
  );

  return data;
};

export interface Webhook {
  id: string;
  client_id: string;
  notification_type: string;
  callback_url: string;
  secret_token: string;
  active: boolean;
}

export const webhooks = {
  all: async () => {
    const { data } = await barracuda.get<Webhook[]>("/subscriptions");

    return data;
  },

  create: async (webhook: Partial<Webhook>) => {
    const { data } = await barracuda.post<Webhook>("/subscriptions", { subscription: webhook });

    return data;
  },
};

export interface Wallet {
  id: string;
  address: string;
  currency: string;
  verified: boolean;
  client_ids: string[];
  created_at: string;
}

export interface User {
  emails: { address: string }[];
  phones: { number: string }[];
  institution: Record<string, unknown>;
  person: Record<string, unknown>;
  policy_acceptances: PolicyAcceptance[];
  uid: string;
  verifications: [];
  wallets: Wallet[];
}

export interface PolicyAcceptance {
  accepted_at: string;
  id: string;
  policy: string;
  revoked_at: string;
  version: string;
}

export const currentUser = {
  get: async () => {
    const { data } = await megalodon.get<User>("/users/me");

    return data;
  },

  acceptPolicy: async (policy: Pick<PolicyAcceptance, "policy" | "version">): Promise<void> => {
    await megalodon.post("/policy-acceptances", policy);
  },
};

export interface ApplicationOptions {
  blocked_nationality_countries: string[];
  blocked_residency_countries: string[];
}

export interface Application {
  name: string;
  uid: string;
  active: boolean;
  secret: string;
  redirect_uri: string;
  image_url: string;
  description: string;
  homepage_url: string;
  verification_redirect_url?: string;
  expirations_enabled: boolean;
  options: ApplicationOptions;
  users_count: number;
  my_role: string;
}

export interface ApplicationRequestBody
  extends Omit<Omit<ApplicationFormData, "blocked_nationality_countries">, "blocked_residency_countries"> {
  options: ApplicationOptions;
}

export const applications = {
  all: async () => {
    const { data } = await catfish.get<Application[]>("/applications");

    return data;
  },

  create: async (application: ApplicationRequestBody) => {
    const { data } = await catfish.post<Application>("/applications", application);

    return data;
  },

  update: async (application: ApplicationRequestBody) => {
    const { data } = await catfish.put<Application>(`/applications/${application.uid}`, application);

    return data;
  },

  resetCredentials: async (application: Application) => {
    const { data } = await catfish.put<Application>(`/applications/${application.uid}/reset-credentials`);

    return data;
  },

  revokeAccessTokens: async (application: Application): Promise<void> => {
    await catfish.post(`/applications/${application.uid}/revoke-access-tokens`);
  },
};

export interface VerificationCasesResponse {
  verification_cases: VerificationCase[];
  offset?: number;
  total: number;
}

export interface Credential {
  id: string;
  address: string;
  blocked_citizenship_countries: string[];
  blocked_residency_countries: string[];
  created_at: string;
  level: string;
  share_pii: boolean;
  verification_case?: MegalodonVerificationCase;
}

export interface CredentialsResponse {
  credentials: Credential[];
  offset?: number;
  total: number;
}

export interface ApplicationUser {
  uid: string;
  email: string;
  role: string;
}

export const applicationUsers = {
  all: async (appUid: string) => {
    const { data } = await catfish.get<ApplicationUser[]>(`/applications/${appUid}/users`);

    return data;
  },

  create: async (application_uid: string, applicationUser: ApplicationUser) => {
    const { data } = await catfish.post<ApplicationUser>(`/applications/${application_uid}/users`, applicationUser);

    return data;
  },

  delete: async (application_uid: string, userUid: string) => {
    const { data } = await catfish.delete<ApplicationUser>(`/applications/${application_uid}/users/${userUid}`);

    return data;
  },
};
