declare global {
  interface Window {
    msCrypto?: typeof window.crypto;
  }
}

export const generateToken = ({
  chars = "abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ23456789_-!@#$%^&*+=~?",
  length = 40,
} = {}): string =>
  Array.from((window.crypto || window.msCrypto).getRandomValues(new Uint8Array(length)), (i) => chars[i % chars.length]).join("");

export default {
  generateToken,
};
