import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import AccountMenu from "components/AccountMenu";
import { User } from "lib/api";

export interface HeaderProps {
  currentUser?: User;
}

export default function Header({ currentUser }: HeaderProps) {
  if (!currentUser) return null;

  return (
    <div className="header">
      <Link to="/" className="header__logo">
        <Trans id="header.admin">Admin</Trans>
      </Link>
      <div className="mobile">
        <AccountMenu currentUser={currentUser} />
      </div>
    </div>
  );
}
