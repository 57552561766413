import React, { Component } from "react";

class BlowUp extends Component {
  componentDidMount() {
    setTimeout(() => {
      throw new Error("Error deliberately raised for testing purposes");
    }, 1000);
  }

  render() {
    return (
      <div>
        <h1>Blowing up!</h1>
      </div>
    );
  }
}

export default BlowUp;
