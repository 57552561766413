import React, { useEffect, useState } from "react";
import { I18nProvider } from "@lingui/react";

import i18n, { dynamicActivate } from "lib/i18n";

import LoadingSpinner from "components/common/LoadingSpinner";
import { useAppSelector } from "lib/hooks";

export interface I18nLoaderProps {
  children: React.ReactNode;
}

function I18nLoader(props: I18nLoaderProps) {
  const [loading, setLoading] = useState(true);
  const language = useAppSelector((state) => state.language);

  const changeLanguage = async () => {
    setLoading(true);
    await dynamicActivate(language);
    setLoading(false);
  };

  useEffect(() => {
    void changeLanguage();
  }, [language]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return <I18nProvider i18n={i18n}>{props.children}</I18nProvider>;
}

export default I18nLoader;
