import { Wallet } from "./api";

export type SoWType =
  | "gifts"
  | "sale_of_property"
  | "sale_of_shares"
  | "sale_of_company"
  | "salary"
  | "inheritance"
  | "lease_revenue"
  | "life_policy"
  | "freelance"
  | "divorce_settlement"
  | "dividends"
  | "pension"
  | "crypto";

export interface PersonalDetails extends Liveness, PersonSoWDetails {
  date_of_birth: string;
  full_name: string;
  identification_document_type: DocumentType;
  identification_document_number: string;
  identification_document_country: string;
  place_of_birth: string;
  identification_document_front_file: string;
  identification_document_back_file: string;
  identification_document_selfie_file: string;
  residential_address: string;
  residential_address_country: string;
  residential_address_proof_file: string;
}

export interface Liveness {
  liveness_audit_best_file: string;
  liveness_audit_least_similar_file: string;
  liveness_audit_open_eyes_file: string;
  liveness_audit_quality1_file: string;
  liveness_audit_quality2_file: string;
  liveness_audit_quality3_file: string;
}

export interface InstitutionalDetails extends InstitutionSoWDetails {
  articles_of_association_file: string;
  beneficial_owner: string;
  certificate_of_corporate_status_file: string;
  certificate_of_good_standing_file: string;
  certificate_of_incorporation_file: string;
  commercial_register: string;
  commercial_register_entry_file: string;
  company_name: string;
  company_seat: string;
  jurisdiction: string;
  legal_form: string;
  managing_directors: string;
  owner_identity_proof_file: string;
  power_of_attorney_file: string;
  secretary_certificate_file: string;
  shareholders_list_file: string;
  transparency_register_entry_file: string;
  unique_identification_number: string;
}

export interface PersonSoWDetails {
  sow_type: SoWType;
  sow_estimated_net_worth_value: string;
  sow_estimated_net_worth_currency: string;
  sow_gifts_name_of_donator: string;
  sow_gifts_relationship_with_donator: string;
  sow_gifts_reason_for_donation: string;
  sow_crypto_bank_statement_file_id: string;
  sow_crypto_source_of_initial_investment_file_id: string;
  sow_crypto_transactions_last_3_months_file_id: string;
  sow_dividends_bank_statement_file_id: string;
  sow_dividends_entitlement_file_id: string;
  sow_divorce_settlement_bank_statement_file_id: string;
  sow_divorce_settlement_proof_file_id: string;
  sow_gifts_bank_statement_file_id: string;
  sow_inheritance_bank_statement_file_id: string;
  sow_inheritance_proof_file_id: string;
  sow_lease_revenue_agreement_file_id: string;
  sow_lease_revenue_bank_statement_file_id: string;
  sow_life_policy_bank_statement_file_id: string;
  sow_life_policy_closing_statement_file_id: string;
  sow_sale_of_company_bank_statement_file_id: string;
  sow_sale_of_company_contract_file_id: string;
  sow_sale_of_property_bank_statement_file_id: string;
  sow_sale_of_property_contract_file_id: string;
  sow_sale_of_shares_bank_statement_file_id: string;
  sow_sale_of_shares_proof_file_id: string;
  sow_pension_bank_statement_file_id: string;
  sow_pension_statement_file_id: string;
  sow_salary_bank_statement_file_id: string;
  sow_salary_last_year_tax_returns_file_id: string;
  sow_salary_pay_slips_last_3_months_file_id: string;
  sow_freelance_bank_statement_file_id: string;
  sow_freelance_last_year_tax_returns_file_id: string;
  sow_freelance_service_contracts_file_id: string;
}

export interface InstitutionSoWDetails {
  sow_balance_sheet_file: string;
  sow_cash_flow_statement_file: string;
  sow_income_statement_file: string;
  sow_unaudited_accountant_name: string;
  sow_unaudited_accountant_professional_body: string;
}

export type WalletCurrency = "BTC" | "ETH";

export interface Email {
  address: string;
  source_type: "journey" | "registration" | "obsolete";
}

export interface VerificationCaseDetails {
  id: string;
  uid: string;
  email?: string;
  emails?: Email[];
  phone: string;
  created_at: string;
  updated_at: string;
  journey_completed_at?: string;
  approved_at?: string;
  credential: Credential;
  status: Status;
  journey_completed: boolean;
  level: string;
  primary_role: "person" | "institution";
  wallets: Wallet[];
}

export interface MegalodonVerificationCase extends VerificationCaseDetails {
  details: Partial<PersonalDetails> & Partial<InstitutionalDetails>;
}

interface VideoIDScreenshots {
  document_front?: string;
  document_back?: string;
  document_selfie?: string;
  document_other?: string;
}

export interface ComplyAdvantageHit {
  source_notes_with_fields: {
    types: string[];
    name: string;
    url: string;
    fields?: {
      field: string;
      values: string[];
    }[];
  }[];

  alternative_names: {
    name: string;
  }[];

  media: {
    url: string;
    date?: string;
    title: string;
    snippet: string;
  }[];

  associates: {
    name: string;
    association: string;
  }[];
}

export interface ComplyAdvantageAMLMatch {
  id: number;
  hit: ComplyAdvantageHit;
}

export interface ComplyAdvantageComplianceReport {
  birth_year: number;
  created_at: string;
  full_name: string;
  id: string;
  vendor_aml_matches: ComplyAdvantageAMLMatch[];
}

export interface LexisNexisAMLMatch {
  birth_date: string;
  first_name: string;
  hit_type: string;
  id: number;
  last_name: string;
  list_name: string;
  list_number: string;
  match_score: number;
  nationality: string;
  reason: string;
}

export interface LexisNexisComplianceReport {
  created_at: string;
  first_name: string;
  id: string;
  last_name: string;
  passport_number: string;
  vendor_aml_matches: LexisNexisAMLMatch[];
}

export type ComplianceReport =
  | ({ vendor: "comply_advantage" } & ComplyAdvantageComplianceReport)
  | ({ vendor: "lexis_nexis" } & LexisNexisComplianceReport);

export interface OctopusVerificationCase {
  screenshots: VideoIDScreenshots;
  compliance_reports: ComplianceReport[];
}

export type VerificationCase = VerificationCaseDetails & Partial<PersonalDetails> & Partial<InstitutionalDetails> & OctopusVerificationCase;

export const documentTypes = ["national_id", "passport", "drivers_license"] as const;

export const statusValues = ["pending", "contacted", "done"] as const;

export const credentialValues = ["approved", "rejected", "pending"] as const;

export const statusLabels = ["Incomplete", "Approved", "Pending", "Rejected"] as const;

export type DocumentType = (typeof documentTypes)[number];
export type Status = (typeof statusValues)[number];
export type Credential = (typeof credentialValues)[number];
export type StatusLabel = (typeof statusLabels)[number];

export const vcStatus = (vc: VerificationCase): StatusLabel => {
  if (vc.journey_completed === false) {
    return "Incomplete";
  }

  if (vc.status === "done" && vc.credential === "approved") {
    return "Approved";
  }

  if (vc.status === "done" && vc.credential === "rejected") {
    return "Rejected";
  }

  return "Pending";
};

export const merge = (megalodonVC: MegalodonVerificationCase, octopusVC: OctopusVerificationCase): VerificationCase => ({
  ...megalodonVC,
  ...megalodonVC.details,
  screenshots: octopusVC.screenshots,
  compliance_reports: octopusVC.compliance_reports,
});
