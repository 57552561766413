import React from "react";
import { t } from "@lingui/macro";

import Document from "components/common/Document";
import { DetailsGroup } from "components/common/DetailsGroup";
import { VerificationCase } from "lib/verification_case";
import { labels as livenessLabels } from "components/checkpoints/shared/liveness";

export const Uniqueness = ({ verificationCase }: { verificationCase: VerificationCase }) => {
  const labelValueList = livenessLabels()
    .filter(({ file }) => !!verificationCase[file])
    .map(({ label, file }) => ({
      label: t({
        id: "verification_case.liveness.*.label",
        message: `Liveness audit image - ${label}`,
      }),
      value: <Document file={verificationCase[file]} key={file} overrideMimeType="image/jpeg" />,
    }));

  return (
    <DetailsGroup
      title={t({
        id: "verification_case.uniqueness.title",
        message: "Uniqueness",
      })}
      labelValueList={labelValueList}
    />
  );
};

export default Uniqueness;
