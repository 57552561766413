import React from "react";
import { WrappedFieldProps } from "redux-form";

export const Textarea = ({ input, meta: { touched, error, warning }, ...props }: WrappedFieldProps) => (
  <div className="form-control">
    <textarea {...input} {...props} />
    {touched && error && <span className="text-danger">{error}</span>}
    {touched && warning && <span className="text-danger">{warning}</span>}
  </div>
);

export default Textarea;
