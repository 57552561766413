import React, { Fragment, useEffect, useState } from "react";
import { t } from "@lingui/macro";

import config from "lib/config";
import { shouldRenderCheckpoint } from "lib/review_checkpoints";
import { fetchMegalodonVerificationCase, fetchOctopusVerificationCase } from "lib/api";
import { useAppSelector } from "lib/hooks";
import { vcStatus, merge, VerificationCase } from "lib/verification_case";

import LoadingSpinner from "components/common/LoadingSpinner";
import Error from "components/common/Error";

import ComplianceReports from "components/checkpoints/ComplianceReports";
import { i18n } from "@lingui/core";

import AgeOfMajority from "components/checkpoints/AgeOfMajority";
import { DetailsGroup, DetailsGroupOption } from "components/common/DetailsGroup";
import ProofOfIdentity from "components/checkpoints/ProofOfIdentity";
import ProofOfResidency from "components/checkpoints/ProofOfResidency";
import Institution from "components/checkpoints/Institution";
import SourceOfWealth from "components/checkpoints/SourceOfWealth";
import Uniqueness from "components/checkpoints/Uniqueness";
import Wallets from "components/checkpoints/Wallets";
import { useParams } from "react-router";

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const OverviewFooter = () => {
  const items = [
    [
      t({
        id: "verification_case.created_at.label",
        message: "KYC start date",
      }),
      t({
        id: "verification_case.created_at.description",
        message: "Date when the user started their journey",
      }),
    ],
    [
      t({
        id: "verification_case.journey_completed_at.label",
        message: "KYC submit date",
      }),
      t({
        id: "verification_case.journey_completed_at.description",
        message: `Date when the user submitted their 
        data — this is not the same as approval date`,
      }),
    ],
    [
      t({
        id: "verification_case.updated_at.label",
        message: "Last update",
      }),
      t({
        id: "verification_case.updated_at.description",
        message: "Date of the last action taken either by the user of by the Fractal team",
      }),
    ],
  ];

  return (
    <small>
      <dl>
        {items.map(([label, text], index) => (
          <Fragment key={index}>
            <dt>
              <strong>{label}</strong>:
            </dt>
            <dd>{text}</dd>
          </Fragment>
        ))}
      </dl>
    </small>
  );
};

const Overview = ({ verificationCase }: { verificationCase: VerificationCase }) => {
  const labelValueList: DetailsGroupOption[] = [
    {
      label: t({ id: "verification_case.uid.label", message: "User ID" }),
      value: verificationCase.uid,
    },
  ];

  if (verificationCase.email) {
    labelValueList.push({
      label: t({ id: "verification_case.email.label", message: "Email" }),
      value: verificationCase.email,
    });
  }

  const obsoleteEmails = verificationCase.emails?.filter((email) => email.source_type === "obsolete") ?? [];
  if (obsoleteEmails.length > 0) {
    labelValueList.push({
      label: t({ id: "verification_case.obsolete_emails.label", message: "Obsolete emails" }),
      value: obsoleteEmails.map((x) => x.address).join(", "),
    });
  }

  labelValueList.push(
    {
      label: t({ id: "verification_case.level.label", message: "Level" }),
      value: verificationCase.level,
    },
    {
      label: t({ id: "verification_case.status.label", message: "Status" }),
      value: vcStatus(verificationCase),
    },
    {
      label: t({ id: "verification_case.created_at.label", message: "KYC start date" }),
      value: i18n.date(new Date(verificationCase.created_at), DATE_FORMAT),
    },
    {
      label: t({ id: "verification_case.journey_completed_at.label", message: "KYC submit date" }),
      value: verificationCase.journey_completed_at ? i18n.date(new Date(verificationCase.journey_completed_at), DATE_FORMAT) : undefined,
    },
    {
      label: t({ id: "verification_case.updated_at.label", message: "Last update" }),
      value: i18n.date(new Date(verificationCase.updated_at), DATE_FORMAT),
    },
  );

  return (
    <DetailsGroup
      title={t({
        id: "verification_case.overview.title",
        message: "Overview",
      })}
      labelValueList={labelValueList}
      footer={<OverviewFooter />}
    />
  );
};

export const ApplicationVerificationCase = (): JSX.Element => {
  const { appId, verificationCaseId } = useParams<{ appId: string; verificationCaseId: string }>();

  const application = useAppSelector((state) => {
    if (state.applications) {
      return state.applications.find((a) => a.uid === appId);
    }
    return null;
  });

  const [verificationCase, setVerificationCase] = useState<VerificationCase | null>(null);
  const [error, setError] = useState<Error | string | null>(null);

  useEffect(() => {
    async function fetchVerificationCase() {
      if (!application) {
        setError(
          t({
            id: "errors.invalid_application",
            message: "Invalid application",
          }),
        );
        return;
      }

      let megalodonVC, octopusVC;

      try {
        megalodonVC = await fetchMegalodonVerificationCase(application, String(verificationCaseId));
      } catch (e: unknown) {
        setError(e as Error);
        return;
      }

      try {
        octopusVC = await fetchOctopusVerificationCase(application, megalodonVC.uid, megalodonVC.level);
      } catch (e) {
        octopusVC = {
          screenshots: {},
          compliance_reports: [],
        };
      }

      if (!error && !!megalodonVC && !!octopusVC) {
        setVerificationCase(merge(megalodonVC, octopusVC));
      }
    }

    void fetchVerificationCase();
  }, [application, verificationCaseId]);

  if (error) return <Error error={error} />;

  if (verificationCase === null) {
    return <LoadingSpinner />;
  } else {
    const splitLevel = verificationCase.level.split("+");

    return (
      <div className="page">
        <div className="page__header">
          <h2>{verificationCase.full_name}</h2>
        </div>

        <div className="user-details">
          <div className="user-details__info">
            <Overview verificationCase={verificationCase} />

            {shouldRenderCheckpoint(splitLevel, "wallet") && <Wallets verificationCase={verificationCase} />}

            {shouldRenderCheckpoint(splitLevel, "age_of_majority") && <AgeOfMajority verificationCase={verificationCase} />}

            {shouldRenderCheckpoint(splitLevel, "uniqueness") && <Uniqueness verificationCase={verificationCase} />}

            {shouldRenderCheckpoint(splitLevel, "proof_of_identity") && <ProofOfIdentity verificationCase={verificationCase} />}

            {verificationCase.primary_role !== "institution" && shouldRenderCheckpoint(splitLevel, "proof_of_residency") && (
              <ProofOfResidency verificationCase={verificationCase} />
            )}

            {verificationCase.primary_role === "institution" && <Institution verificationCase={verificationCase} />}

            {shouldRenderCheckpoint(splitLevel, "source_of_wealth") && <SourceOfWealth verificationCase={verificationCase} />}

            {config.ENABLED_COMPLIANCE_REPORTS === "1" && <ComplianceReports verificationCase={verificationCase} />}
          </div>
        </div>
      </div>
    );
  }
};

export default ApplicationVerificationCase;
