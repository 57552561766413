import { getType } from "typesafe-actions";

import { ActionTypes, setApplicationUsers, addApplicationUser, removeApplicationUser } from "actions/applicationUsers";

import { ApplicationUser } from "lib/api";

export type StateType = {
  [key: string]: ApplicationUser[];
};

export default (state: StateType = {}, action: ActionTypes): StateType => {
  switch (action.type) {
    case getType(setApplicationUsers):
      return { ...state, [action.payload[0]]: action.payload[1] };

    case getType(addApplicationUser): {
      const oldArray = state[action.payload[0]];
      const newArray = [...oldArray, action.payload[1]];
      return { ...state, [action.payload[0]]: newArray };
    }

    case getType(removeApplicationUser): {
      const oldArray = state[action.payload[0]];
      const newArray = oldArray.filter((user) => user.uid !== action.payload[1]);
      return { ...state, [action.payload[0]]: newArray };
    }

    default:
      return state;
  }
};
