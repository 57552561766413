import React from "react";
import { Link } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import { DropdownMenu } from "@trustfractal/seabed";

import { logout } from "lib/session";
import { username, initials } from "lib/username";
import { User } from "lib/api";

export interface AccountMenuProps {
  currentUser: User;
}

export default function AccountMenu({ currentUser }: AccountMenuProps) {
  return (
    <DropdownMenu
      title={
        username(currentUser) ||
        t({
          id: "account_menu.title",
          message: "Account",
        })
      }
      current={initials(currentUser)}
      className="account-menu"
      type="round"
    >
      <Link to="/apps" className="dropdown-menu__option">
        <Trans id="account_menu.applications">Integrations</Trans>
      </Link>

      <a
        // TODO: remove handler after issue is solved https://github.com/trustfractal/seabed/issues/5
        onClick={() => window.open("https://docs.developer.fractal.id/")}
        href="https://docs.developer.fractal.id/"
        target="_blank"
        rel="noopener noreferrer"
        className="dropdown-menu__option"
      >
        <Trans id="account_menu.api_docs">API documentation</Trans>
      </a>

      <hr />

      <span className="dropdown-menu__option" onClick={logout}>
        <Trans id="account_menu.logout">Logout</Trans>
      </span>
    </DropdownMenu>
  );
}
