import React, { useState } from "react";
import { Trans } from "@lingui/macro";

import { saveToClipboard } from "lib/browser";

const NOTICE_DISPLAY_DURATION = 1000;

export interface CopyableTextProps {
  text: string;
}

export const CopyableText = ({ text }: CopyableTextProps) => {
  const [isCopied, setCopied] = useState(false);

  const copyToClipboard = () => {
    void saveToClipboard(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), NOTICE_DISPLAY_DURATION);
    });
  };

  return (
    <span className="copyable-text" onClick={copyToClipboard}>
      {text}{" "}
      {isCopied && (
        <small>
          <Trans id="copyable_text.saved">Saved to clipboard!</Trans>
        </small>
      )}
    </span>
  );
};

export default CopyableText;
