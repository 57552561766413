import { getType } from "typesafe-actions";
import { ActionTypes, setCurrentUser } from "actions/currentUser";
import { User } from "lib/api";

type State = User | null;

export default (state: State = null, action: ActionTypes): State => {
  switch (action.type) {
    case getType(setCurrentUser):
      return action.payload;

    default:
      return state;
  }
};
