import React, { Fragment } from "react";
import { Trans } from "@lingui/macro";

export interface DetailsGroupOption {
  label?: string;
  value?: React.ReactNode;
}

export const DetailsGroup = ({
  title,
  labelValueList = [],
  footer,
}: {
  title: string;
  labelValueList: DetailsGroupOption[];
  footer?: JSX.Element;
}) => (
  <div className="user-details__info-group">
    <h3>{title}</h3>

    <section className="user-details__info-group--body">
      {labelValueList.length === 0 ? (
        <p>
          <Trans id="checkpoint.no_data.label">No data available</Trans>
        </p>
      ) : (
        <dl>
          {labelValueList.map(({ label, value }) => (
            <Fragment key={label}>
              <dt>{label}</dt>
              <dd>{value}</dd>
            </Fragment>
          ))}
        </dl>
      )}
    </section>

    {footer && <footer>{footer}</footer>}
  </div>
);

export default DetailsGroup;
