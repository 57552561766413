const reviewCheckpoints = Object.freeze({
  age_of_majority: ["basic", "v1", "light", "plus"],
  pep_sanctions: ["v1", "plus", "basic"],
  investor_accreditation: ["accreditation"],
  personal_information: ["v1", "plus", "light", "basic"],
  proof_of_identity: ["v1", "basic", "plus"],
  proof_of_identity_liveness: ["liveness"],
  proof_of_identity_selfie: ["v1", "selfie"],
  proof_of_identity_video: ["video"],
  proof_of_residency: ["v1", "plus"],
  social_security_number: [],
  source_of_wealth: ["sow"],
  uniqueness: ["uniqueness"],
  wallet: [
    "wallet",
    "wallet-ada",
    "wallet-algo",
    "wallet-btc",
    "wallet-eth",
    "wallet-kar",
    "wallet-sol",
    "wallet-substrate",
    "wallet-near",
  ],
});

type CheckpointName = keyof typeof reviewCheckpoints;

const checkpointLevels = (checkpoint: CheckpointName): string[] => reviewCheckpoints[checkpoint];

export const shouldRenderCheckpoint = (journeyLevels: string[], reviewCheckpoint: CheckpointName): boolean =>
  checkpointLevels(reviewCheckpoint).some((level) => journeyLevels.includes(level));
