import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Trans } from "@lingui/macro";
import { useNavigate, useParams } from "react-router-dom";

import { goBackUrl } from "lib/browser";

import { updateApplication, transformApplicationToFormData } from "actions/applications";

import ApplicationForm, { ApplicationFormData } from "components/ApplicationForm";
import { useAppSelector } from "lib/hooks";

export interface ApplicationEditParams {
  appId: string;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateApplication,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ApplicationEditPropsFromRedux = ConnectedProps<typeof connector>;

export function ApplicationEdit({ updateApplication }: ApplicationEditPropsFromRedux) {
  const navigate = useNavigate();
  const { appId } = useParams<Partial<ApplicationEditParams>>();
  const application = useAppSelector((state) => state.applications?.find((app) => app.uid === appId));

  useEffect(() => {
    if (!application) navigate("/apps");
  }, []);

  if (!application) return null;

  const onSubmit = (values: ApplicationFormData) => updateApplication(values).then((data) => navigate(`/apps/${data.uid}`));

  const onCancel = () => navigate(`/apps/${application.uid}`);

  // Trasnform application to be editable
  const formData = transformApplicationToFormData(application);

  return (
    <div className="page">
      <div className="page__header">
        <h2>
          <Trans id="application.edit_application">Edit integration</Trans>
        </h2>
      </div>

      <div className="page__content application">
        <div className="applications__form--close" onClick={() => navigate(goBackUrl())}>
          <Trans id="applications.go_back">Back</Trans>
        </div>

        <ApplicationForm onSubmit={onSubmit} initialValues={formData} image_url={application.image_url} onCancel={onCancel} isEdit />
      </div>
    </div>
  );
}

export default connector(ApplicationEdit);
