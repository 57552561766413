import "styles/application.scss";

import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Outlet } from "react-router";
import * as Sentry from "@sentry/browser";

import config from "lib/config";

import { setCustomStyleProperties } from "lib/browser";

import { fetchCurrentUser } from "actions/currentUser";

// components for seabed
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import LoadingSpinner from "components/common/LoadingSpinner";

export class App extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.props.fetchCurrentUser().finally(() => this.setState({ loading: false }));

    Sentry.init({
      dsn: config.SENTRY_DSN,
      environment: config.SENTRY_ENVIRONMENT,
      // This variable cames from webpack.DefinePlugin
      release: process.env.SENTRY_RELEASE ?? "",
      ignoreUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
      beforeSend: (event, hint) => {
        // Don't send Axios 4xx errors to Sentry
        const error = hint.originalException;

        if (error?.response?.status && error.response.status >= 400 && error.response.status < 500) {
          return null;
        }

        return event;
      },
    });

    setCustomStyleProperties();
  }

  render() {
    const { currentUser } = this.props;
    const { loading } = this.state;

    return (
      <div className="fractal-id-developer-dashboard">
        <div className="sidebar__wrapper">
          <Header currentUser={currentUser} />
          <Sidebar currentUser={currentUser} />
        </div>

        <div className="content">
          {loading && <LoadingSpinner />}
          {!loading && (
            <div role="group" style={{ outline: "none" }} tabIndex="-1">
              <Outlet />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default compose(connect(({ currentUser }) => ({ currentUser }), { fetchCurrentUser }))(App);
