import { getType } from "typesafe-actions";

import { ActionTypes, setApplications, addApplication, changeApplication } from "actions/applications";

import { Application } from "lib/api";

type State = null | Application[];
export default (state: State = null, action: ActionTypes): State => {
  switch (action.type) {
    case getType(setApplications):
      return action.payload;

    case getType(addApplication):
      if (state === null) {
        return [action.payload];
      } else {
        return [...state, action.payload];
      }

    case getType(changeApplication):
      if (state === null) {
        return state;
      }

      return state.map((application) => (application.uid === action.payload.uid ? action.payload : application));

    default:
      return state;
  }
};
