import React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { presence, url, length } from "redux-form-validators";
import { Trans, t } from "@lingui/macro";

import Input from "components/fields/Input";
import Switch from "components/fields/Switch";
import Token from "components/fields/Token";

import { Webhook } from "lib/api";

export interface WebhookFormProps {
  title: string;
  description: string;
  initialValues?: Partial<Webhook>;
}

const WebhookForm = (props: WebhookFormProps & InjectedFormProps<Webhook, WebhookFormProps>) => {
  const { valid, submitting, handleSubmit, title, description } = props;

  return (
    <form className="webhook-form" onSubmit={handleSubmit}>
      <h4 className="webhook-form__title">{title}</h4>

      <p className="webhook-form__description">{description}</p>

      <Field component="input" type="hidden" name="client_id" />

      <Field component="input" type="hidden" name="notification_type" />

      <div className="form-group">
        <label htmlFor="callback_url">
          <Trans id="webhook.callback_url">Callback URL</Trans>
        </label>

        <Field
          component={Input}
          type="url"
          name="callback_url"
          validate={[
            presence(),
            url({
              protocols: ["https"],
              local: false,
              message: t({
                id: "validators.url_required_https",
                message: "URL is invalid, required HTTPS protocol",
              }),
            }),
          ]}
        />
      </div>

      <div className="form-group">
        <label htmlFor="secret_token">
          <Trans id="webhook.secret_token">Secret token</Trans>
        </label>
        <Field component={Token} name="secret_token" validate={[presence(), length({ min: 32 })]} />
      </div>

      <Field
        component={Switch}
        name="active"
        title={t({
          id: "active",
          message: "Active",
        })}
      />

      <div className="webhook-form__actions">
        <button type="submit" className="btn primary" disabled={!valid || submitting}>
          <Trans id="save">Save</Trans>
        </button>
      </div>
    </form>
  );
};

export default reduxForm<Webhook, WebhookFormProps>({ form: "webhook" })(WebhookForm);
