import React from "react";

export const LoadingSpinner = () => (
  <div className="loading__container">
    <div className="loading__spinner" data-testid="loading-spinner">
      {new Array(12).fill(0).map((_, i) => (
        <div key={i} />
      ))}
    </div>
  </div>
);

export default LoadingSpinner;
