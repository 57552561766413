import { parse, ParsedQs } from "qs";

export const queryParams = <T = ParsedQs>(): T => parse(window.location.search, { ignoreQueryPrefix: true }) as T;

export const calculateBackUrl = (url: string): string =>
  "/".concat(
    url
      .split("/")
      .filter((it) => it !== "")
      .slice(0, -1)
      .join("/"),
  );

export const goBackUrl = () => calculateBackUrl(window.location.pathname);

export const setCustomStyleProperties = (): void => {
  const fullHeight = window.innerHeight;
  document.documentElement.style.setProperty("--full-height", `${fullHeight}px`);
};

export const fileToBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => resolve(reader.result);
    reader.onerror = (error): void => reject(error);
  });

export const saveToClipboard = async (text: string): Promise<void> => {
  if (window.navigator.clipboard) {
    return window.navigator.clipboard.writeText(text);
  }

  // fallback
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("Copy");
  textArea.remove();

  return Promise.resolve();
};

export const pageRefresh = (target: string): void => window.location.assign(target);
