import { Liveness } from "lib/verification_case";
import { t } from "@lingui/macro";

export const labels = (): { file: keyof Liveness; label: string }[] => [
  {
    file: "liveness_audit_best_file",
    label: t({ id: "verification_case.liveness.best.label", message: "Best" }),
  },
  {
    file: "liveness_audit_least_similar_file",
    label: t({ id: "verification_case.liveness.least_similar.label", message: "Least similar" }),
  },
  {
    file: "liveness_audit_open_eyes_file",
    label: t({ id: "verification_case.liveness.open_eyes.label", message: "Open eyes" }),
  },
  {
    file: "liveness_audit_quality1_file",
    label: t({ id: "verification_case.liveness.quality1.label", message: "Quality (1)" }),
  },
  {
    file: "liveness_audit_quality2_file",
    label: t({ id: "verification_case.liveness.quality2.label", message: "Quality (2)" }),
  },
  {
    file: "liveness_audit_quality3_file",
    label: t({ id: "verification_case.liveness.quality3.label", message: "Quality (3)" }),
  },
];
