import React from "react";

import icon from "assets/images/error.svg";

export interface ErrorProps {
  error: string | Error;
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export const Error = ({ error }: ErrorProps) => (
  <div className="error">
    <img src={icon} alt="Error!" />
    <p>{error.toString()}</p>
  </div>
);

export default Error;
