import React from "react";
import { Trans, t } from "@lingui/macro";
import { generateToken } from "lib/cryptography";
import { WrappedFieldProps } from "redux-form";

export const Token = ({ input, input: { onChange, value }, meta: { touched, error, warning }, ...props }: WrappedFieldProps) => {
  if (!value && !touched) onChange(generateToken());

  return (
    <div className="form-control Token">
      <div className="Token__container">
        <input className="Token__input" {...input} {...props} />

        <span
          className="Token__regenerate"
          onClick={() => onChange(generateToken())}
          title={t({
            id: "fields.token.regenerate",
            message: "Re-generate",
          })}
        >
          &#8635;
        </span>
      </div>

      {touched && error && <span className="text-danger">{error}</span>}
      {touched && warning && <span className="text-danger">{warning}</span>}

      <span className="Token__description">
        <Trans id="fields.token.description">
          Secret token should be at least 32 characters long, do not contain vocabulary words or repetitions. The best practice is combining
          several of the following character classes: letters (lower and upper case), digits, special characters.
        </Trans>
      </span>
    </div>
  );
};

Token.defaultProps = {
  type: "text",
};

export default Token;
