import React from "react";
import { t } from "@lingui/macro";

import { DetailsGroup, DetailsGroupOption } from "components/common/DetailsGroup";
import Document from "components/common/Document";
import { PersonSoWDetails, SoWType, VerificationCase } from "lib/verification_case";

export const SourceOfWealth = ({ verificationCase }: { verificationCase: VerificationCase }) => {
  if (!verificationCase.sow_type) {
    return null;
  }

  const sowFields: Record<SoWType, Record<string, string>> = {
    gifts: {
      sow_gifts_name_of_donator: t({ id: "verification_case.sow.sow_gifts_name_of_donator.label", message: "Name of donator" }),
      sow_gifts_reason_for_donation: t({ id: "verification_case.sow.sow_gifts_reason_for_donation.label", message: "Reason for donation" }),
      sow_gifts_relationship_with_donator: t({
        id: "verification_case.sow.sow_gifts_relationship_with_donator.label",
        message: "Relationship with donator",
      }),
      sow_gifts_bank_statement_file: t({ id: "verification_case.sow.sow_gifts_bank_statement_file.label", message: "Bank statement file" }),
    },
    sale_of_property: {
      sow_sale_of_property_bank_statement_file: t({
        id: "verification_case.sow.sow_sale_of_property_bank_statement_file.label",
        message: "Bank statement",
      }),
      sow_sale_of_property_contract_file: t({
        id: "verification_case.sow.sow_sale_of_property_contract_file.label",
        message: "Property sale contract file",
      }),
    },
    sale_of_shares: {
      sow_sale_of_shares_bank_statement_file: t({
        id: "verification_case.sow.sow_sale_of_shares_bank_statement_file.label",
        message: "Bank statement file",
      }),
      sow_sale_of_shares_proof_file: t({
        id: "verification_case.sow.sow_sale_of_shares_proof_file.label",
        message: "Sale of shares proof file",
      }),
    },
    sale_of_company: {
      sow_sale_of_company_bank_statement_file: t({
        id: "verification_case.sow.sow_sale_of_company_bank_statement_file.label",
        message: "Bank statement file",
      }),
      sow_sale_of_company_contract_file: t({
        id: "verification_case.sow.sow_sale_of_company_contract_file.label",
        message: "Sale of company contract file",
      }),
    },
    salary: {
      sow_salary_bank_statement_file: t({
        id: "verification_case.sow.sow_salary_bank_statement_file.label",
        message: "Bank statement file",
      }),
      sow_salary_last_year_tax_returns_file: t({
        id: "verification_case.sow.sow_salary_last_year_tax_returns_file.label",
        message: "Last year's tax returns file",
      }),
      sow_salary_pay_slips_last_3_months_file: t({
        id: "verification_case.sow.sow_salary_pay_slips_last_3_months_file.label",
        message: "Last 3 months of pay slips file",
      }),
    },
    inheritance: {
      sow_inheritance_proof_file: t({ id: "verification_case.sow.sow_inheritance_proof_file.label", message: "Inheritance proof file" }),
      sow_inheritance_bank_statement_file: t({
        id: "verification_case.sow.sow_inheritance_bank_statement_file.label",
        message: "Bank statement file",
      }),
    },
    lease_revenue: {
      sow_lease_revenue_agreement_file: t({
        id: "verification_case.sow.sow_lease_revenue_agreement_file.label",
        message: "Lease agreement file",
      }),
      sow_lease_revenue_bank_statement_file: t({
        id: "verification_case.sow.sow_lease_revenue_bank_statement_file.label",
        message: "Bank statement file",
      }),
    },
    life_policy: {
      sow_life_policy_bank_statement_file: t({
        id: "verification_case.sow.sow_life_policy_bank_statement_file.label",
        message: "Bank statement file",
      }),
      sow_life_policy_closing_statement_file: t({
        id: "verification_case.sow.sow_life_policy_closing_statement_file.label",
        message: "Life policy closing statement",
      }),
    },
    freelance: {
      sow_freelance_bank_statement_file: t({
        id: "verification_case.sow.sow_freelance_bank_statement_file.label",
        message: "Bank statement file",
      }),
      sow_freelance_last_year_tax_returns_file: t({
        id: "verification_case.sow.sow_freelance_last_year_tax_returns_file.label",
        message: "Last year's tax returns file",
      }),
      sow_freelance_service_contracts_file: t({
        id: "verification_case.sow.sow_freelance_service_contracts_file.label",
        message: "Freelance service contracts file",
      }),
    },
    divorce_settlement: {
      sow_divorce_settlement_bank_statement_file: t({
        id: "verification_case.sow.sow_divorce_settlement_bank_statement_file.label",
        message: "Bank statement file",
      }),
      sow_divorce_settlement_proof_file: t({
        id: "verification_case.sow.sow_divorce_settlement_proof_file.label",
        message: "Settlement proof file",
      }),
    },
    dividends: {
      sow_dividends_bank_statement_file: t({
        id: "verification_case.sow.sow_dividends_bank_statement_file.label",
        message: "Bank statement file",
      }),
      sow_dividends_entitlement_file: t({
        id: "verification_case.sow.sow_dividends_entitlement_file.label",
        message: "Dividends entitlement file",
      }),
    },
    pension: {
      sow_pension_bank_statement_file: t({
        id: "verification_case.sow.sow_pension_bank_statement_file.label",
        message: "Pension bank statement file",
      }),
      sow_pension_statement_file: t({ id: "verification_case.sow.sow_pension_statement_file.label", message: "Pension statement file" }),
    },
    crypto: {
      sow_crypto_source_of_initial_investment_file: t({
        id: "verification_case.sow.sow_crypto_source_of_initial_investment_file.label",
        message: "Source of initial investment",
      }),
      sow_crypto_bank_statement_file: t({
        id: "verification_case.sow.sow_crypto_bank_statement_file.label",
        message: "Bank statement file",
      }),
      sow_crypto_transactions_last_3_months_file: t({
        id: "verification_case.sow.sow_crypto_transactions_last_3_months_file.label",
        message: "Last 3 months transactions file",
      }),
    },
  };

  const labelValueList: DetailsGroupOption[] = [
    {
      label: t({ id: "verification_case.sow.type.label", message: "Source of wealth" }),
      value: verificationCase.sow_type,
    },
    {
      label: t({ id: "verification_case.sow.net_worth.label", message: "Net worth (estimate)" }),
      value:
        verificationCase.sow_estimated_net_worth_value && verificationCase.sow_estimated_net_worth_currency
          ? `${verificationCase.sow_estimated_net_worth_value} ${verificationCase.sow_estimated_net_worth_currency}`
          : "",
    },
  ];

  labelValueList.push(
    ...Object.entries(sowFields[verificationCase.sow_type]).map(([field, label]) => ({
      label: label,
      value: field.indexOf("file") !== -1 ? <Document file={verificationCase[field as keyof PersonSoWDetails]} key={field} /> : field,
    })),
  );

  return (
    <DetailsGroup
      title={t({
        id: "verification_case.sow.title",
        message: "Source of wealth",
      })}
      labelValueList={labelValueList}
    />
  );
};

export default SourceOfWealth;
