import React, { useState, useEffect } from "react";
import { Trans, t } from "@lingui/macro";
import { useNavigate, useParams } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import axios from "axios";

import { goBackUrl } from "lib/browser";
import { ApplicationUser } from "lib/api";
import { createApplicationUser, fetchApplicationUsers, deleteApplicationUser } from "actions/applicationUsers";
import ApplicationUserForm from "components/ApplicationUserForm";
import Error from "components/common/Error";
import store from "store";

const mapDispatchToProps = {
  createApplicationUser,
  fetchApplicationUsers,
  deleteApplicationUser,
};

const connector = connect(undefined, mapDispatchToProps);

type ApplicationClientsPropsFromRedux = ConnectedProps<typeof connector>;

export function ApplicationClients({
  createApplicationUser,
  fetchApplicationUsers,
  deleteApplicationUser,
}: ApplicationClientsPropsFromRedux) {
  const { appId } = useParams() as { appId: string };
  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(false);
  useEffect(() => {
    setDataLoading(true);
    void fetchApplicationUsers(appId)
      .then(() => {
        setTableData(getTableData());
      })
      .finally(() => {
        setDataLoading(false);
      });
  }, []);

  const [error, setError] = useState<Error | string | null>(null);
  const responseError = t({
    id: "errors.connectivity",
    message: "Connection error, please try again.",
  });
  const forbiddenError = t({
    id: "errors.forbidden",
    message:
      "Adding the team member is prohibited. Possible reasons: you have no rights to add team members, this member is already added.",
  });

  const dataTableHeaders = [
    [
      t({
        id: "application_users.user_list.email",
        message: "Email",
      }),
      "60%",
    ],
    [
      t({
        id: "application_users.user_list.role",
        message: "Role",
      }),
      "20%",
    ],
    [
      t({
        id: "application_users.user_list.remove",
        message: "Remove",
      }),
      "20%",
    ],
  ];

  const getTableData = (): ApplicationUser[] => {
    return store.getState().applicationUsers[appId];
  };
  const [tableData, setTableData] = useState<ApplicationUser[]>(getTableData());

  const myEmail = (): string => {
    return store.getState().currentUser!.emails[0].address;
  };

  const renderRow = (user: ApplicationUser) => {
    return (
      <>
        <td>{user.email}</td>
        <td>{user.role}</td>
        <td>
          {user.email !== myEmail() && (
            <button type="button" className="btn btn-grey" onClick={() => onDelete(user.uid)}>
              <Trans id="application_users.remove_button">Remove</Trans>
            </button>
          )}
        </td>
      </>
    );
  };

  const onSubmit = (values: ApplicationUser) => {
    createApplicationUser(appId, values).then(
      () => {
        setTableData(getTableData());
      },
      (e) => {
        let forbiddenType = false;
        if (axios.isAxiosError(e) && e?.response?.status && e.response.status < 500) forbiddenType = true;
        if (forbiddenType) {
          setError(forbiddenError);
        } else {
          setError(responseError);
        }
      },
    );
  };

  const onDelete = (userUid: string) => {
    void deleteApplicationUser(appId, userUid).then(() => {
      setTableData(getTableData());
    });
  };

  return (
    <div className="page">
      <div className="page__header">
        <h2>
          <Trans id="application_users.title">Team members</Trans>
        </h2>
      </div>
      <div className="page__content application">
        <div className="applications__form--close" onClick={() => navigate(goBackUrl())}>
          <Trans id="applications.go_back">Back</Trans>
        </div>
        <div className="application__card">
          <div className="application__card--header">
            <Trans id="application_users.new_team_member">Add a new team member</Trans>
          </div>
          <div className="application__card--body">
            <ApplicationUserForm onSubmit={onSubmit} />
            {error !== null && <Error error={error} />}
          </div>
        </div>
        <div className="application__card">
          <div className="application__card--body">
            <div className="datatable">
              <table>
                <thead>
                  <tr>
                    {dataTableHeaders.map(([label, width], i) => (
                      <th key={i} style={{ width }}>
                        {label}
                      </th>
                    ))}
                  </tr>
                </thead>
                {!dataLoading && <tbody>{tableData && tableData.map((user, i) => <tr key={i}>{renderRow(user)}</tr>)}</tbody>}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connector(ApplicationClients);
