import { createAction } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";

import { RootState } from "store";
import { applicationUsers, ApplicationUser } from "lib/api";

export const setApplicationUsers = createAction("SET_APPLICATION_USERS")<[string, ApplicationUser[]]>();
export const addApplicationUser = createAction("ADD_APPLICATION_USER")<[string, ApplicationUser]>();
export const removeApplicationUser = createAction("REMOVE_APPLICATION_USER")<[string, string]>();

export type ActionTypes =
  | ReturnType<typeof setApplicationUsers>
  | ReturnType<typeof addApplicationUser>
  | ReturnType<typeof removeApplicationUser>;

type PluralThunkAction = ThunkAction<Promise<ApplicationUser[]>, RootState, null, ActionTypes>;
type SingularThunkAction = ThunkAction<Promise<ApplicationUser>, RootState, null, ActionTypes>;

export const createApplicationUser =
  (applicationUid: string, applicationUser: ApplicationUser): SingularThunkAction =>
  async (dispatch): Promise<ApplicationUser> => {
    const data = await applicationUsers.create(applicationUid, applicationUser);

    dispatch(addApplicationUser([applicationUid, data]));
    return data;
  };

export const deleteApplicationUser =
  (applicationUid: string, userUid: string): SingularThunkAction =>
  async (dispatch): Promise<ApplicationUser> => {
    const data = await applicationUsers.delete(applicationUid, userUid);

    dispatch(removeApplicationUser([applicationUid, userUid]));
    return data;
  };

export const fetchApplicationUsers =
  (applicationUid: string): PluralThunkAction =>
  async (dispatch): Promise<ApplicationUser[]> => {
    const data = await applicationUsers.all(applicationUid);

    dispatch(setApplicationUsers([applicationUid, data]));
    return data;
  };
