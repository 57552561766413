import React from "react";
import dayjs from "dayjs";
import { t } from "@lingui/macro";

import { DetailsGroup } from "components/common/DetailsGroup";
import { VerificationCase } from "lib/verification_case";

export const AgeOfMajority = ({ verificationCase }: { verificationCase: VerificationCase }) => {
  const labelValueList = [
    {
      label: t({ id: "verification_case.dob.label", message: "Date of birth" }),
      value: verificationCase.date_of_birth,
    },
    {
      label: t({ id: "verification_case.age.label", message: "Age" }),
      value: verificationCase.date_of_birth && dayjs().diff(verificationCase.date_of_birth, "year", false).toString(),
    },
  ];

  return (
    <DetailsGroup
      title={t({
        id: "verification_case.age_of_majority.title",
        message: "Age of majority",
      })}
      labelValueList={labelValueList}
    />
  );
};

export default AgeOfMajority;
