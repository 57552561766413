import React from "react";
import { WrappedFieldProps } from "redux-form";

export interface SwitchProps {
  title: string;
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export const Switch = ({ input, meta: { touched, error, warning }, title, ...props }: SwitchProps & WrappedFieldProps) => (
  <div className="form-control Switch">
    <div className="Switch__container">
      <div className="Switch__switcher">
        <label>
          <input type="checkbox" checked={input.value} {...input} {...props} />
          <span className="inner"></span>
          <span className="handle"></span>
        </label>
      </div>
      <span className="Switch__title">{title}</span>
    </div>

    {touched && error && <span className="text-danger">{error}</span>}
    {touched && warning && <span className="text-danger">{warning}</span>}
  </div>
);

export default Switch;
