import { getType } from "typesafe-actions";
import { ActionTypes, setWebhooks, replaceWebhook } from "actions/webhooks";
import { Webhook } from "lib/api";

type State = null | Webhook[];

export default (state: State = null, action: ActionTypes): State => {
  switch (action.type) {
    case getType(setWebhooks):
      return action.payload;

    case getType(replaceWebhook):
      if (state === null) {
        return state;
      }

      return state.filter((webhook) => webhook.id !== action.payload.id).concat(action.payload);

    default:
      return state;
  }
};
