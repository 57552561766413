import React from "react";
import cx from "classnames";
import { t } from "@lingui/macro";

export interface DataTableProps<T> {
  headerLabels: [string, string][];
  offset?: number;
  total: number;
  limit: number;
  renderRow: (item: T) => React.ReactNode;
  data: T[];
  fetchPage: (offset: number) => void;
}

export function DataTable<T>({ headerLabels, data, renderRow, total, offset = 0, limit, fetchPage }: DataTableProps<T>) {
  const canGoPrev = offset - limit >= 0;
  const canGoNext = offset + limit < total;
  const fetchPrev = () => canGoPrev && fetchPage(offset - limit);
  const fetchNext = () => canGoNext && fetchPage(offset + limit);

  return (
    <div className="datatable">
      <table>
        <thead>
          <tr>
            {headerLabels.map(([label, width], i) => (
              <th key={i} style={{ width }}>
                {label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((resource, i) => (
            <tr key={i}>{renderRow(resource)}</tr>
          ))}
        </tbody>
      </table>
      <ul className="datatable__pagination">
        <li className={cx({ disabled: !canGoPrev })} onClick={() => fetchPrev()}>
          {t({
            id: "data_table.pagination.prev",
            message: "prev",
          })}
        </li>
        <li className={cx({ disabled: !canGoNext })} onClick={() => fetchNext()}>
          {t({
            id: "data_table.pagination.next",
            message: "next",
          })}
        </li>
      </ul>
    </div>
  );
}

export default DataTable;
