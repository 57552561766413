import React, { useEffect } from "react";

import { fetchApplications } from "actions/applications";
import { fetchWebhooks } from "actions/webhooks";

import LoadingSpinner from "components/common/LoadingSpinner";
import { useAppDispatch, useAppSelector } from "lib/hooks";
import { Outlet } from "react-router";

export default function ApplicationFetcher() {
  const dispatch = useAppDispatch();
  const applications = useAppSelector((state) => state.applications);

  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    dispatch(fetchApplications());
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    dispatch(fetchWebhooks());
  }, []);

  if (applications) {
    return <Outlet />;
  }

  return <LoadingSpinner />;
}
