import React from "react";
import { Modal } from "@trustfractal/seabed";

export interface NoticeProps {
  icon?: string;
  children: React.ReactNode;
}

export const Notice = ({ icon, children }: NoticeProps) => (
  <Modal
    className="modal--notice"
    open
    disableClose
    cssTransitionProps={{
      appear: true,
    }}
  >
    <div className={`icon icon--${icon ?? ""}`} />
    {children}
  </Modal>
);

export default Notice;
