import { addValidator, url, UrlValidatorOptions, Validator } from "redux-form-validators";
import { t } from "@lingui/macro";

// FIXME: remove when https://github.com/gtournie/redux-form-validators/pull/66 is merged
interface URL {
  protocol?: string;
  hash?: string;
  host?: string;
  ipv4?: string;
  ipv6?: string;
  port?: number;
  search?: string;
  path?: string;
  basicAuth?: {
    username: string;
    password?: string;
  };
}

interface UrlValidatorWithDefaults extends UrlValidatorOptions {
  allowBlank?: boolean;
}

interface ParseUrl {
  (options?: UrlValidatorOptions): Validator;
  parseURL: (url: string, options?: UrlValidatorOptions) => URL | null;
}

export const urlWithLocalhostValidator = addValidator({
  defaultMessage: t({
    id: "validators.url_with_localhost",
    message: "is not a valid URL: requires https protocol (except for http://localhost)",
  }),
  validator: (options: UrlValidatorWithDefaults | undefined, value: string, _allValues) => {
    const info = (url as ParseUrl).parseURL(value, options);

    if (!info) return false;

    return info.protocol === "https" || info.host === "localhost";
  },
}) as (options?: UrlValidatorWithDefaults) => Validator;

export const urlWithoutHashValidator = addValidator({
  defaultMessage: t({
    id: "validators.url_without_hash",
    message: "is not a valid URL: fragment/hash (#example) not allowed",
  }),
  validator: (options: UrlValidatorWithDefaults | undefined, value: string, _allValues) => {
    const info = (url as ParseUrl).parseURL(value, options);

    if (!info) return false;

    return info.hash === undefined;
  },
}) as (options?: UrlValidatorWithDefaults) => Validator;
