import React from "react";
import { t } from "@lingui/macro";

import { VerificationCase } from "lib/verification_case";
import { countryName } from "lib/labels";
import Document from "components/common/Document";
import DetailsGroup from "components/common/DetailsGroup";

export const ProofOfResidency = ({ verificationCase }: { verificationCase: VerificationCase }) => {
  const labelValueList = [
    {
      label: t({
        id: "verification_case.residential_address_country.label",
        message: "Country",
      }),
      value: countryName(verificationCase.residential_address_country),
    },
    {
      label: t({ id: "verification_case.residential_address.label", message: "Address" }),
      value: verificationCase.residential_address,
    },
    {
      label: t({ id: "verification_case.proof_of_residency.label", message: "Proof of residency" }),
      value: <Document file={verificationCase.residential_address_proof_file} key="residential_address_proof_file" />,
    },
  ];

  return (
    <DetailsGroup
      title={t({
        id: "verification_case.proof_of_residency.title",
        message: "Proof of residency",
      })}
      labelValueList={labelValueList}
    />
  );
};

export default ProofOfResidency;
