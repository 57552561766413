import React, { useEffect } from "react";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { pageRefresh } from "lib/browser";

import ProtectedLayout from "layouts/Protected";
import RequiresPolicyAcceptanceLayout from "layouts/RequiresPolicyAcceptance";
import ApplicationFetcherLayout from "layouts/ApplicationFetcher";

// @ts-expect-error still JSX
import App from "components/App";
// @ts-expect-error still JSX
import Home from "components/pages/Home";
// @ts-expect-error still JSX
import Applications from "components/pages/Applications";
import Session from "components/pages/Session";
import BlowUp from "components/pages/BlowUp";
import NotFound from "components/pages/NotFound";

import ApplicationNew from "components/pages/ApplicationNew";
import ApplicationClients from "components/pages/ApplicationClients";
import ApplicationView from "components/pages/ApplicationView";
import ApplicationCredential from "components/pages/ApplicationCredential";
import ApplicationEdit from "components/pages/ApplicationEdit";
import ApplicationStats from "components/pages/ApplicationStats";
import ApplicationVerificationCase from "components/pages/ApplicationVerificationCase";

import { dataProcessingAgreement } from "lib/nautilusLinks";

const DataProcessingAgreement = () => {
  useEffect(() => {
    pageRefresh(dataProcessingAgreement);
  }, []);

  return null;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route path="/" element={<Home />} />
      <Route path="/session" element={<Session />} />

      <Route element={<ProtectedLayout />}>
        <Route element={<RequiresPolicyAcceptanceLayout />}>
          <Route path="/apps/new" element={<ApplicationNew />} />

          <Route element={<ApplicationFetcherLayout />}>
            <Route path="/apps" element={<Applications />} />
            <Route path="/apps/:appId" element={<ApplicationView />} />
            <Route path="/apps/:appId/team" element={<ApplicationClients />} />
            <Route path="/apps/:appId/edit" element={<ApplicationEdit />} />
            <Route path="/apps/:appId/stats" element={<ApplicationStats />} />
            <Route path="/apps/:appId/users/:verificationCaseId" element={<ApplicationVerificationCase />} />
            <Route path="/apps/:appId/credentials/:credentialId" element={<ApplicationCredential />} />
          </Route>
        </Route>
      </Route>

      <Route path="/blow-up" element={<BlowUp />} />

      <Route path="/documents/data-processing-agreement" element={<DataProcessingAgreement />} />

      <Route element={<NotFound />} path="*" />
    </Route>,
  ),
);

export default router;
