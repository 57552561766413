import React from "react";
import ReactSelect, { SingleValue, Props as ReactSelectProps } from "react-select";
import isMobile from "is-mobile";
import { WrappedFieldMetaProps } from "redux-form";

export interface FilterOption {
  value: string;
  label: string;
}

export interface SelectProps extends ReactSelectProps<FilterOption, false> {
  options: FilterOption[];
  afterChange?: (value: SingleValue<FilterOption>) => void;
  meta: Pick<WrappedFieldMetaProps, "error" | "warning" | "touched">;
  input: {
    name: string;
    onChange: (value: SingleValue<FilterOption>) => void;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class Select extends React.Component<SelectProps & { input: any }> {
  static defaultProps = {
    isSearchable: !isMobile(),
  };

  onChange = (newValue: SingleValue<FilterOption>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { input, afterChange } = this.props;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    input.onChange(newValue);

    if (afterChange) {
      afterChange(newValue);
    }
  };

  render() {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      input,
      "aria-label": ariaLabel,
      options,
      isSearchable,
      isMulti,
      className = "Select",
      classNamePrefix = "Select",
      placeholder,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      meta: { touched, error, warning },
    } = this.props;

    return (
      <div className="form-control">
        <ReactSelect<FilterOption, false>
          {...input}
          aria-label={ariaLabel}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          value={options.find((opt) => opt.value === input.value) || null}
          onChange={this.onChange}
          options={options}
          isSearchable={isSearchable}
          isMulti={isMulti}
          className={className}
          classNamePrefix={classNamePrefix}
          placeholder={placeholder}
        />
        {touched && error && <span className="text-danger">{error}</span>}
        {touched && warning && <span className="text-danger">{warning}</span>}
      </div>
    );
  }
}

export default Select;
