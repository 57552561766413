import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import Select, { FilterOption } from "components/fields/Select";
import { Props as ReactSelectProps, SingleValue } from "react-select";

import { Application, fetchCredentials, CredentialsResponse, Credential } from "lib/api";
import { useDebounce } from "lib/hooks";
import LoadingSpinner from "components/common/LoadingSpinner";
import DataTable from "components/common/DataTable";
import Error from "components/common/Error";
import arrow from "assets/images/arrow-large-right-orange.svg";
import { i18n } from "@lingui/core";

const Header = {
  get labels() {
    return [
      [
        t({
          id: "credential_explorer.header.address",
          message: "Address",
        }),
        "50%",
      ],
      [
        t({
          id: "credential_explorer.header.level",
          message: "Level",
        }),
        "25%",
      ],
      [
        t({
          id: "credential_explorer.header.timestamp",
          message: "Timestamp",
        }),
        "25%",
      ],
      ["", "5%"], // Details arrow column
    ] as Array<[string, string]>;
  },
};

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const LIMIT = 20;

const TextFilter = (props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => (
  <div className="form-control">
    <input
      data-testid="text-filter"
      name="filter"
      type="text"
      placeholder={t({
        id: "credential_explorer.filter.text_placeholder",
        message: "Query",
      })}
      {...props}
    />
  </div>
);

type CredentialSearchSelectProps = Pick<ReactSelectProps<FilterOption>, "name"> & {
  value?: string;
  options: FilterOption[];
  onChange: (option: SingleValue<FilterOption>) => void;
};

const CredentialSearchSelect = ({ name, options, value, onChange }: CredentialSearchSelectProps) => {
  const meta = {
    error: false,
    touched: false,
    warning: false,
  };
  const input = {
    name,
    onChange,
    value,
  };

  return (
    <Select
      aria-label="Credential filter"
      input={input}
      options={options}
      meta={meta}
      isMulti={false}
      isSearchable={false}
      placeholder={t({
        id: "credential_explorer.filter.select_placeholder",
        message: "Filter by...",
      })}
    />
  );
};

const Reset = ({ onReset, filtering }: { onReset: () => void; filtering: boolean }) => (
  <div className="user-explorer__filter-reset">
    <button className="btn btn-grey" onClick={onReset} disabled={filtering}>
      {filtering && <Trans id="credential_explorer.filter.filtering">Filtering...</Trans>}
      {!filtering && <Trans id="credential_explorer.filter.reset">Reset</Trans>}
    </button>
  </div>
);

const CredentialExplorer = ({ application }: { application: Application }): JSX.Element => {
  const [credentials, setCredentials] = useState<CredentialsResponse | null>(null);
  const [error, setError] = useState<Error | string | null>(null);
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const [filtering, setFiltering] = useState(false);

  const [textFilter, setTextFilter] = useState("");

  const fetchPage = (offset = 0, filter?: string, query?: string) => {
    setFiltering(true);

    fetchCredentials(application, offset, filter, query)
      .then((data) => {
        setCredentials({ offset, ...data });
      })
      .catch((error: unknown) => {
         
        setError(error as Error);
      })
      .finally(() => {
        setFiltering(false);
      });
  };

  const handleFilterChange = (option: SingleValue<FilterOption>) => {
    setFilter(option?.value || "");
  };

  const handleQueryChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setTextFilter(value);
  };

  const handleReset = () => {
    setFilter(undefined);
    setTextFilter("");
  };

  const delayedTextFilter = useDebounce(textFilter, 1000);

  useEffect(() => {
    const offset = credentials?.offset || 0;
    fetchPage(offset, filter, delayedTextFilter);
  }, [delayedTextFilter, filter]);

  const options = [
    {
      value: "wallet_address",
      label: t({
        id: "credential_explorer.filter.wallet_address",
        message: "Wallet address",
      }),
    },
  ];

  if (error) return <Error error={error} />;

  if (!credentials) return <LoadingSpinner />;

  return (
    <>
      <form className="form-group__inline">
        <CredentialSearchSelect name="credential-filter-select" options={options} value={filter} onChange={handleFilterChange} />

        <TextFilter onChange={handleQueryChange} disabled={!filter || filtering} value={textFilter} />

        <Reset onReset={handleReset} filtering={filtering} />
      </form>

      <DataTable<Credential>
        headerLabels={Header.labels}
        data={credentials?.credentials}
        total={credentials.total}
        offset={credentials.offset}
        limit={LIMIT}
        fetchPage={(offset = 0) => fetchPage(offset, filter, delayedTextFilter)}
        renderRow={(credential: Credential) => (
          <>
            <td>{credential.address}</td>
            <td>{credential.level}</td>
            <td>{i18n.date(new Date(credential.created_at), DATE_FORMAT)}</td>
            <td>
              <Link to={`/apps/${application.uid}/credentials/${credential.id}`}>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                <img src={arrow} />
              </Link>
            </td>
          </>
        )}
      />
    </>
  );
};

export default CredentialExplorer;
