import { PolicyAcceptance, User } from "lib/api";

export const termsAndConditions = {
  policy: "developerTermsAndConditions",
  version: "v4",
};

export const gdpr = {
  policy: "gdpr",
  version: "v2",
};

export const requiredAcceptPolicy = ({ policy_acceptances: policyAcceptances }: User): boolean => {
  const isTermsAndConditions = (x: PolicyAcceptance) => x.policy === termsAndConditions.policy && x.version === termsAndConditions.version;
  const isGdpr = (x: PolicyAcceptance) => x.policy === gdpr.policy && x.version === gdpr.version;

  let termsAndConditionsPresence;
  let gdprPresence;

  policyAcceptances.forEach((x) => {
    if (x.revoked_at !== null) return;

    if (isGdpr(x)) gdprPresence = x;
    if (isTermsAndConditions(x)) termsAndConditionsPresence = x;
  });

  return !termsAndConditionsPresence || !gdprPresence;
};
