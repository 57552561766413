import React, { useState } from "react";
import classnames from "classnames";

export interface ButtonProps {
  type?: "button" | "submit";
  onClick?: (ev?: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
  feedbackDuration?: number;
  loadingLabel?: string;
  sucessLabel?: string;
  failureLabel?: string;
  loading?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
}

function Button({
  type = "button",
  onClick = () => Promise.resolve(),
  feedbackDuration = 3000,
  loadingLabel = "Loading...",
  sucessLabel = "Success!",
  failureLabel = "Error!",
  className,
  children,
  disabled,
}: ButtonProps) {
  const [loading, setLoading] = useState(false);
  const [temporaryLabel, setTemporaryLabel] = useState<string | null>(null);

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (type === "submit") {
      return;
    }

    setLoading(true);

    const result = onClick(ev);

    if (result?.then) {
      result
        .then(() => setClearableLabel(sucessLabel))
        .catch(() => setClearableLabel(failureLabel))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const setClearableLabel = (label: string) => {
    setTemporaryLabel(label);

    window.setTimeout(() => {
      setTemporaryLabel(null);
    }, feedbackDuration);
  };

  const cx = classnames(className, { loading, disabled });

  return (
    <button type={type} onClick={handleClick} className={cx} disabled={loading || disabled}>
      {loading && loadingLabel}
      {!loading && (temporaryLabel || children)}
    </button>
  );
}

export default Button;
