import React, { Fragment } from "react";

import { VerificationCase, LexisNexisComplianceReport, ComplyAdvantageComplianceReport } from "lib/verification_case";

import i18n from "lib/i18n";
import Disclosure from "components/common/Disclosure";

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

interface Props {
  verificationCase: VerificationCase;
}

export const ComplianceReports: React.FC<Props> = ({ verificationCase }) => (
  <div className="user-details__info-group">
    <h2>Compliance reports</h2>
    <section className="user-details__info-group--body">
      {verificationCase.compliance_reports.map((report, index) => (
        <div key={`report-${index}`}>
          <h3>Report #{index + 1}</h3>
          {report.vendor === "lexis_nexis" && <LexisNexis report={report} />}
          {report.vendor === "comply_advantage" && <ComplyAdvantage report={report} />}
        </div>
      ))}
    </section>
  </div>
);

const LexisNexis: React.FC<{ report: LexisNexisComplianceReport }> = ({ report }) => (
  <>
    <dl>
      <dt>Requested at</dt>
      <dd>{i18n.date(new Date(report.created_at), DATE_FORMAT)}</dd>

      <dt>First name</dt>
      <dd>{report.first_name}</dd>

      <dt>Last name</dt>
      <dd>{report.last_name}</dd>

      <dt>Matches</dt>
      <dd>{report.vendor_aml_matches.length}</dd>
    </dl>

    <dl>
      {report.vendor_aml_matches.map((match, index) => (
        <Fragment key={match.id}>
          <h4>Match #{index + 1}</h4>
          <div>
            <h4>
              {match.match_score}% hit in {match.list_name} ({match.list_number})
            </h4>
            <div>first name: {match.first_name}</div>
            <div>last name: {match.last_name}</div>
            <div>birth date: {match.birth_date}</div>
            <div>nationality: {match.nationality}</div>
            <div>hit type: {match.hit_type}</div>
            <div>reason: {match.reason}</div>
          </div>
        </Fragment>
      ))}
    </dl>
  </>
);

const ComplyAdvantage: React.FC<{ report: ComplyAdvantageComplianceReport }> = ({ report }) => (
  <>
    <dl>
      <dt>Requested at</dt>
      <dd>{i18n.date(new Date(report.created_at), DATE_FORMAT)}</dd>

      <dt>Full name</dt>
      <dd>{report.full_name}</dd>

      <dt>Birth year</dt>
      <dd>{report.birth_year}</dd>

      <dt>Matches</dt>
      <dd>{report.vendor_aml_matches.length}</dd>
    </dl>

    {report.vendor_aml_matches.map((match, index) => (
      <Disclosure title={`Match #${index + 1}`} key={`match-${index}`}>
        <h4>Match #{index + 1}</h4>
        <dl>
          <dt>Alternative names</dt>
          <dd>{match.hit.alternative_names.map((an) => an.name).join(", ")}</dd>
        </dl>

        {match.hit.source_notes_with_fields.map((sn, idx) => (
          <Fragment key={`match-${match.id}-note-${idx}`}>
            <h5>Source note #{idx + 1}</h5>
            <dl>
              <dt>Name</dt>
              <dd>{sn.name}</dd>
              <dt>URL</dt>
              <dd>{sn.url}</dd>
              <dt>Types</dt>
              <dd>types: {sn.types.join(", ")}</dd>
            </dl>

            {sn.fields && (
              <>
                <strong>Fields:</strong>
                <dl>
                  {sn.fields?.map((f, j) => (
                    <Fragment key={`match-${match.id}-note-${idx}-field-${j}`}>
                      <dt>{f.field}</dt>
                      <dd>{f.values.join(", ")}</dd>
                    </Fragment>
                  ))}
                </dl>
              </>
            )}
          </Fragment>
        ))}

        {match.hit.media.length > 0 && (
          <>
            <h5>Media</h5>
            {match.hit.media.map((m, idx) => (
              <dl key={`match-${match.id}-media-${idx}`}>
                <dt>Title</dt>
                <dd>{m.title}</dd>
                <dt>Date</dt>
                <dd>{m.date && i18n.date(new Date(m.date), DATE_FORMAT)}</dd>
                <dt>Url</dt>
                <dd>{m.url}</dd>
                <dt>Snippet</dt>
                <dd>{m.snippet}</dd>
              </dl>
            ))}
          </>
        )}

        {match.hit.associates.length > 0 && (
          <>
            <h5>Associates</h5>
            <dl>
              {match.hit.associates.map((associate, idx) => (
                <Fragment key={`match-${match.id}-associate-${idx}`}>
                  <dt>{associate.association}</dt>
                  <dd>{associate.name}</dd>
                </Fragment>
              ))}
            </dl>
          </>
        )}
      </Disclosure>
    ))}
  </>
);

export default ComplianceReports;
