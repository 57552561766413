import { createAction } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";

import { RootState } from "store";
import { applications, Application, ApplicationRequestBody } from "lib/api";
import { ApplicationFormData } from "components/ApplicationForm";

export const addApplication = createAction("ADD_APPLICATION")<Application>();
export const changeApplication = createAction("CHANGE_APPLICATION")<Application>();
export const setApplications = createAction("SET_APPLICATIONS")<Application[]>();

export type ActionTypes = ReturnType<typeof addApplication> | ReturnType<typeof changeApplication> | ReturnType<typeof setApplications>;

type VoidThunkAction = ThunkAction<void, RootState, null, ActionTypes>;
type PluralThunkAction = ThunkAction<Promise<Application[]>, RootState, null, ActionTypes>;
type SingularThunkAction = ThunkAction<Promise<Application>, RootState, null, ActionTypes>;

export const fetchApplications =
  (): PluralThunkAction =>
  async (dispatch): Promise<Application[]> => {
    const data = await applications.all();

    dispatch(setApplications(data));
    return data;
  };

export const transformFormDataToRequest = (data: ApplicationFormData): ApplicationRequestBody => ({
  ...data,
  image_url: data.image_url?.startsWith("data:image") ? data.image_url : undefined,
  options: {
    blocked_nationality_countries: Object.entries(data.blocked_nationality_countries ?? {})
      .filter((x) => x[1])
      .map((x) => x[0]),
    blocked_residency_countries: Object.entries(data.blocked_residency_countries ?? {})
      .filter((x) => x[1])
      .map((x) => x[0]),
  },
});

export const transformApplicationToFormData = (data: ApplicationRequestBody): ApplicationFormData => {
  let blocked_residency_countries = {};
  if (data.options?.blocked_residency_countries) {
    blocked_residency_countries = data.options.blocked_residency_countries.reduce((prev, cur) => ({ ...prev, [cur]: true }), {});
  }

  let blocked_nationality_countries = {};
  if (data.options?.blocked_nationality_countries) {
    blocked_nationality_countries = data.options.blocked_nationality_countries.reduce((prev, cur) => ({ ...prev, [cur]: true }), {});
  }

  return {
    ...data,
    blocked_residency_countries,
    blocked_nationality_countries,
  };
};

export const createApplication =
  (application: ApplicationFormData): SingularThunkAction =>
  async (dispatch): Promise<Application> => {
    const data = await applications.create(transformFormDataToRequest(application));

    dispatch(addApplication(data));
    return data;
  };

export const updateApplication =
  (application: ApplicationFormData): SingularThunkAction =>
  async (dispatch): Promise<Application> => {
    const data = await applications.update(transformFormDataToRequest(application));

    dispatch(changeApplication(data));
    return data;
  };

export const resetCredentials =
  (application: Application): SingularThunkAction =>
  async (dispatch): Promise<Application> => {
    const data = await applications.resetCredentials(application);

    dispatch(changeApplication(data));
    return data;
  };

export const revokeAccessTokens =
  (application: Application): VoidThunkAction =>
  async (dispatch): Promise<void> => {
    await applications.revokeAccessTokens(application);

    void dispatch(fetchApplications());
  };
