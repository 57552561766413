import { setUser } from "@sentry/browser";
import { createAction } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";

import { RootState } from "store";
import { currentUser, User } from "lib/api";
import { gdpr, termsAndConditions } from "lib/policyAcceptance";

export const setCurrentUser = createAction("SET_CURRENT_USER")<User>();

export type ActionTypes = ReturnType<typeof setCurrentUser>;

export const fetchCurrentUser =
  (): ThunkAction<Promise<User>, RootState, null, ActionTypes> =>
  async (dispatch): Promise<User> => {
    const data = await currentUser.get();

    setUser({
      uid: data.uid,
    });

    dispatch(setCurrentUser(data));
    return data;
  };

export const acceptPolicy =
  (): ThunkAction<Promise<void>, RootState, null, ActionTypes> =>
  async (dispatch): Promise<void> => {
    await currentUser.acceptPolicy(termsAndConditions);
    await currentUser.acceptPolicy(gdpr);

    void dispatch(fetchCurrentUser());
  };
