import React from "react";
import { t } from "@lingui/macro";

import { DetailsGroup } from "components/common/DetailsGroup";
import Document from "components/common/Document";
import { VerificationCase } from "lib/verification_case";

export const Institution = ({ verificationCase }: { verificationCase: VerificationCase }) => {
  const labelValueList = [
    {
      label: t({ id: "verification_case.institution.company_name.label", message: "Company name" }),
      value: verificationCase.company_name,
    },
    {
      label: t({ id: "verification_case.institution.legal_form.label", message: "Legal form" }),
      value: verificationCase.legal_form,
    },
    {
      label: t({ id: "verification_case.institution.jurisdiction.label", message: "Jurisdiction" }),
      value: verificationCase.jurisdiction,
    },
    {
      label: t({ id: "verification_case.institution.residential_address_country.label", message: "Principal place of business" }),
      value: verificationCase.residential_address_country,
    },
    {
      label: t({ id: "verification_case.institution.company_seat.label", message: "Company seat and address" }),
      value: verificationCase.company_seat,
    },
    {
      label: t({ id: "verification_case.institution.residential_address_proof_file.label", message: "Place of business proof file" }),
      value: <Document file={verificationCase.residential_address_proof_file} key="residential_address_proof_file" />,
    },
    {
      label: t({ id: "verification_case.institution.commercial_register.label", message: "Commercial register" }),
      value: verificationCase.commercial_register,
    },
    {
      label: t({ id: "verification_case.institution.commercial_register_entry_file.label", message: "Commercial register entry file" }),
      value: <Document file={verificationCase.commercial_register_entry_file} key="commercial_register_entry_file" />,
    },
    {
      label: t({ id: "verification_case.institution.managing_directors.label", message: "Managing directors" }),
      value: verificationCase.managing_directors,
    },
    {
      label: t({ id: "verification_case.institution.beneficial_owner.label", message: "Beneficial owners" }),
      value: verificationCase.beneficial_owner,
    },
    {
      label: t({
        id: "verification_case.institution.owner_identity_proof_file.label",
        message: "Proof of identity and address of beneficial owners and legal representatives",
      }),
      value: <Document file={verificationCase.owner_identity_proof_file} key="owner_identity_proof_file" />,
    },
    {
      label: t({ id: "verification_case.institution.shareholders_list_file.label", message: "Shareholders list" }),
      value: <Document file={verificationCase.shareholders_list_file} key="shareholders_list_file" />,
    },
    {
      label: t({ id: "verification_case.institution.articles_of_association_file.label", message: "Articles of association" }),
      value: <Document file={verificationCase.articles_of_association_file} key="articles_of_association_file" />,
    },
    {
      label: t({ id: "verification_case.institution.power_of_attorney_file.label", message: "Power of attorney" }),
      value: <Document file={verificationCase.power_of_attorney_file} key="power_of_attorney_file" />,
    },
    {
      label: t({ id: "verification_case.institution.transparency_register_entry_file.label", message: "Transparency register entry" }),
      value: <Document file={verificationCase.transparency_register_entry_file} key="transparency_register_entry_file" />,
    },
    {
      label: t({ id: "verification_case.institution.certificate_of_good_standing_file.label", message: "Certificate of good standing" }),
      value: <Document file={verificationCase.certificate_of_good_standing_file} key="certificate_of_good_standing_file" />,
    },
    {
      label: t({ id: "verification_case.institution.secretary_certificate_file.label", message: "Secretary’s Certificate" }),
      value: <Document file={verificationCase.secretary_certificate_file} key="secretary_certificate_file" />,
    },
    {
      label: t({ id: "verification_case.institution.certificate_of_incorporation_file.label", message: "Certificate of incorporation" }),
      value: <Document file={verificationCase.certificate_of_incorporation_file} key="certificate_of_incorporation_file" />,
    },
    {
      label: t({
        id: "verification_case.institution.certificate_of_corporate_status_file.label",
        message: "Certificate of corporate status",
      }),
      value: <Document file={verificationCase.certificate_of_corporate_status_file} key="certificate_of_corporate_status_file" />,
    },
  ];

  const shouldIncludeSoW = verificationCase.level.split("+").includes("sow");
  if (shouldIncludeSoW) {
    labelValueList.push(
      {
        label: t({ id: "verification_case.institution.sow_estimated_net_worth_value.label", message: "Company net worth value" }),
        value:
          verificationCase.sow_estimated_net_worth_value && verificationCase.sow_estimated_net_worth_currency
            ? `${verificationCase.sow_estimated_net_worth_value} ${verificationCase.sow_estimated_net_worth_currency}`
            : "",
      },
      {
        label: t({ id: "verification_case.institution.sow_balance_sheet_file.label", message: "Source of wealth: company balance sheet" }),
        value: <Document file={verificationCase.sow_balance_sheet_file} key="sow_balance_sheet_file" />,
      },
      {
        label: t({
          id: "verification_case.institution.sow_cash_flow_statement_file.label",
          message: "Source of wealth: company cash flow statement",
        }),
        value: <Document file={verificationCase.sow_cash_flow_statement_file} key="sow_cash_flow_statement_file" />,
      },
      {
        label: t({ id: "verification_case.institution.sow_income_statement_file.label", message: "Source of wealth: income statement" }),
        value: <Document file={verificationCase.sow_income_statement_file} key="sow_income_statement_file" />,
      },
      {
        label: t({
          id: "verification_case.institution.sow_unaudited_accountant_name.label",
          message: "Name of accountant that prepared financial statements",
        }),
        value: verificationCase.sow_unaudited_accountant_name,
      },
      {
        label: t({
          id: "verification_case.institution.sow_unaudited_accountant_professional_body.label",
          message: "Professional body of accountant",
        }),
        value: verificationCase.sow_unaudited_accountant_professional_body,
      },
    );
  }

  return (
    <DetailsGroup
      title={t({
        id: "verification_case.institution.title",
        message: "Company details",
      })}
      labelValueList={labelValueList}
    />
  );
};

export default Institution;
