import { Disclosure as HeadlessDisclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import React from "react";

export interface DisclosureProps {
  title: string;
  children: React.ReactNode;
}

export default function Disclosure({ title, children }: DisclosureProps) {
  return (
    <HeadlessDisclosure as="div" className="disclosure" defaultOpen={false}>
      <DisclosureButton className="disclosure__button">
        <span className="disclosure__icon" aria-hidden="true"></span>
        <span>{title}</span>
      </DisclosureButton>
      <DisclosurePanel className="disclosure__panel">{children}</DisclosurePanel>
    </HeadlessDisclosure>
  );
}
