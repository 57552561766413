import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { fetchToken } from "lib/api";
import session from "lib/session";
import { Trans } from "@lingui/macro";
import config from "lib/config";

import { fetchCurrentUser } from "actions/currentUser";

import LoadingSpinner from "components/common/LoadingSpinner";
import { isAxiosError } from "axios";
import { useAppDispatch, useAppSelector } from "lib/hooks";
import { queryParams } from "lib/browser";

export default function Session() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { code } = queryParams<{ code: string }>();
  const currentUser = useAppSelector((state) => state.currentUser);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchToken(code)
      .then(({ data }) => session.setToken(data.access_token))
      .then(() => dispatch(fetchCurrentUser()))
      .catch((error) => {
        if (isAxiosError<{ error: string }>(error)) {
          setError(error.response?.data.error as string);
        }
      });
  }, []);

  useEffect(() => {
    if (currentUser) {
      navigate("/apps");
    }
  }, [currentUser]);

  if (error && error !== "otp_missing") {
    return (
      <div className="home text-center">
        <h2>Error</h2>
        <p className="text-center">
          <strong>{error}</strong>
        </p>
      </div>
    );
  }

  if (error && error === "otp_missing") {
    return (
      <div className="home text-center">
        <h2>
          <Trans id="session.error.otp_missing">Two-Factor authentication required</Trans>
        </h2>
        <p className="text-center">
          <Trans id="session.error.otp_description">To access developer dashboard you have to enable Two-Factor authentication.</Trans>
        </p>
        <a href={`${config.NAUTILUS_BASE_URL}/security-center`} className="btn primary">
          <Trans id="session.error.otp_description.security_center">Go to security center</Trans>
        </a>
      </div>
    );
  }

  return <LoadingSpinner />;
}
