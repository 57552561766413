import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import { logout } from "lib/session";
import { username } from "lib/username";
import { User } from "lib/api";

export interface SidebarProps {
  currentUser?: User;
}

export default function Sidebar({ currentUser }: SidebarProps) {
  if (!currentUser) return null;

  return (
    <div className="sidebar">
      <Link to="/apps">
        <Trans id="account_menu.applications">Integrations</Trans>
      </Link>
      <a href="https://docs.developer.fractal.id/" target="_blank" rel="noopener noreferrer">
        <Trans id="account_menu.api_docs">API documentation</Trans>
      </a>

      <div className="sidebar__menu">
        {currentUser && (
          <>
            <small className="header__account">{username(currentUser)}</small>

            <button className="header__logout" onClick={logout}>
              <Trans id="account_menu.logout">Logout</Trans>
            </button>
          </>
        )}
      </div>
    </div>
  );
}
