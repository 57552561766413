import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import axios from "axios";

import countries from "assets/countries.json";
import { shouldRenderCheckpoint } from "lib/review_checkpoints";
import { fetchMegalodonCredential, Credential } from "lib/api";
import { useAppSelector } from "lib/hooks";
import { merge, VerificationCase } from "lib/verification_case";

import Error from "components/common/Error";
import LoadingSpinner from "components/common/LoadingSpinner";

import AgeOfMajority from "components/checkpoints/AgeOfMajority";
import { DetailsGroup, DetailsGroupOption } from "components/common/DetailsGroup";
import ProofOfIdentity from "components/checkpoints/ProofOfIdentity";
import ProofOfResidency from "components/checkpoints/ProofOfResidency";
import Institution from "components/checkpoints/Institution";
import SourceOfWealth from "components/checkpoints/SourceOfWealth";
import Uniqueness from "components/checkpoints/Uniqueness";
import Wallets from "components/checkpoints/Wallets";

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const Overview = ({
  credential,
  blockedCitizenshipCountries,
  blockedResidencyCountries,
}: {
  credential: Credential;
  blockedCitizenshipCountries: string[];
  blockedResidencyCountries: string[];
}) => {
  const labelValueList: DetailsGroupOption[] = [
    {
      label: t({ id: "credential.wallet_address.label", message: "Wallet address" }),
      value: credential.address,
    },
    {
      label: t({ id: "credential.blocked_citizenship_countries.label", message: "Blocked citizenship countries" }),
      value: blockedCitizenshipCountries.join(", "),
    },
    {
      label: t({ id: "credential.blocked_residency_countries.label", message: "Blocked residency countries" }),
      value: blockedResidencyCountries.join(", "),
    },
    {
      label: t({ id: "credential.level.label", message: "Level" }),
      value: credential.level,
    },
    {
      label: t({ id: "credential.created_at.label", message: "Timestamp" }),
      value: i18n.date(new Date(credential.created_at), DATE_FORMAT),
    },
  ];

  return (
    <DetailsGroup
      title={t({
        id: "credential.overview.title",
        message: "Overview",
      })}
      labelValueList={labelValueList}
    />
  );
};

const connectivityError = t({
  id: "errors.connectivity",
  message: "Connection error, please try again.",
});

export const ApplicationCredential = (): JSX.Element => {
  const { appId, credentialId } = useParams<{ appId: string; credentialId: string }>();

  const application = useAppSelector((state) => state.applications?.find((a) => a.uid === appId));

  const [credential, setCredential] = useState<Credential | null>(null);
  const [verificationCase, setVerificationCase] = useState<VerificationCase | null>(null);
  const [error, setError] = useState<Error | string | null>(null);

  useEffect(() => {
    async function fetchCredential() {
      if (!application) {
        setError(
          t({
            id: "errors.invalid_application",
            message: "Invalid application",
          }),
        );
        return;
      }

      try {
        const credential = await fetchMegalodonCredential(application, String(credentialId));
        setCredential(credential);
        if (!credential.verification_case) return;

        setVerificationCase(
          merge(credential.verification_case, {
            screenshots: {},
            compliance_reports: [],
          }),
        );
      } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.status === 404) {
          setError(
            t({
              id: "errors.invalid_credential",
              message: "Invalid credential",
            }),
          );
        } else {
          setError(connectivityError);
        }
      }
    }

    void fetchCredential();
  }, [application, credentialId]);

  const countryList = useMemo(
    () =>
      countries.reduce<Record<string, string>>((acc, country) => {
        acc[country.value] = country.label;
        return acc;
      }, {}),
    [countries],
  );

  const [blockedCitizenshipCountries, blockedResidencyCountries] = useMemo(() => {
    if (!credential) return [[], []];

    return [
      credential.blocked_citizenship_countries.map((c) => countryList[c]),
      credential.blocked_residency_countries.map((c) => countryList[c]),
    ];
  }, [credential]);

  if (error) return <Error error={error} />;

  if (!credential) return <LoadingSpinner />;

  const splitLevel = credential.level.split("+");

  return (
    <div className="page">
      <div className="page__header">
        <h2>Credential</h2>
      </div>

      <div className="user-details">
        <div className="user-details__info">
          <Overview
            credential={credential}
            blockedCitizenshipCountries={blockedCitizenshipCountries}
            blockedResidencyCountries={blockedResidencyCountries}
          />

          {verificationCase && (
            <>
              <h5>
                <Trans id="application_credential.verification_case.title">Supporting data</Trans>
              </h5>
              <hr></hr>

              {shouldRenderCheckpoint(splitLevel, "wallet") && <Wallets verificationCase={verificationCase} />}

              {shouldRenderCheckpoint(splitLevel, "age_of_majority") && <AgeOfMajority verificationCase={verificationCase} />}

              {shouldRenderCheckpoint(splitLevel, "uniqueness") && <Uniqueness verificationCase={verificationCase} />}

              {shouldRenderCheckpoint(splitLevel, "proof_of_identity") && <ProofOfIdentity verificationCase={verificationCase} />}

              {verificationCase.primary_role !== "institution" && shouldRenderCheckpoint(splitLevel, "proof_of_residency") && (
                <ProofOfResidency verificationCase={verificationCase} />
              )}

              {verificationCase.primary_role === "institution" && <Institution verificationCase={verificationCase} />}

              {shouldRenderCheckpoint(splitLevel, "source_of_wealth") && <SourceOfWealth verificationCase={verificationCase} />}
            </>
          )}

          {credential && credential.share_pii && !credential.verification_case && (
            <Error
              error={t({
                id: "application_credential.error.missing_verification_case",
                message: "Missing verification case",
              })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationCredential;
